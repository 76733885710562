<template>
  <div class="setting-filiale-th">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2">Gestion des sociétés</div>
      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addFilialeModal
        >
          <font-awesome-icon icon="plus" /> Ajouter une société
        </b-button>
        <!-- Add new filiale Modal -->
        <b-modal
          ref="addFilialeModal"
          id="addFilialeModal"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          no-close-on-backdrop
          modal-class="cutsom-modal-bootstrap  cutsom-modal-bootstrap-filiale-th"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une société</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addFilialeModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitFiliale"
                class="form-modal-custom-style"
              >
                <!-- <b-form-group class="mb-0 ml-3" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="filialeToAdd.type"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    @change="onChangeTypeForm(filialeToAdd.type)"
                    required
                  ></b-form-radio-group>
                </b-form-group> -->
                <b-form-group
                  label="Type de société*"
                  label-for="type_societe"
                  class="input-modal-champ col-4"
                >
                  <b-form-select
                    autocomplete="off"
                    id="type-societe"
                    v-model="filialeToAdd.type"
                    required
                    @change="onChangeTypeForm(filialeToAdd.type)"
                    :options="options"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
                <div
                  class="ModelCol checkbox-style-type mt-3"
                  v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                >
                  <b-form-checkbox
                    v-model="filialeToAdd.is_societe_support"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    class="check-th"
                  >
                    Support
                  </b-form-checkbox>
                  <b-form-group
                    v-if="filialeToAdd.is_societe_support == true"
                    label="Type de société support"
                    label-for="filiale-type_societe-support"
                    class="input-modal-champ mt-1 col-4"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-type_societe-support"
                      v-model="filialeToAdd.type_societe_support"
                      required
                      :options="getTypeSocieteSupportTh"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                >
                  <b-form-group
                    label="Nom de société *"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-name"
                      v-model="filialeToAdd.name"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Type de société"
                    label-for="filiale-name"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-name"
                      v-model="filialeToAdd.type_societe"
                      :options="typeSteFilter"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Couleur"
                    class="nput-modal-champ col-2 pl-0"
                  >
                    <v-input-colorpicker
                      colorpick-eyedropper-active="false"
                      class="color_picker_width ml-2"
                      v-model="filialeToAdd.couleur"
                    />
                  </b-form-group>
                </div>
                <div
                  class="ModelCol mr-3"
                  v-if="filialeToAdd && filialeToAdd.type === 'particulier'"
                >
                  <b-form-group
                    label="Civilité *"
                    label-for="filiale-civilite"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-civilite"
                      v-model="filialeToAdd.civilite"
                      :options="genreList"
                      class="b-form-select-raduis"
                      required
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Prénom *"
                    label-for="filiale-prenom"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-prenom"
                      v-model="filialeToAdd.prenom"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Nom de famille *"
                    label-for="filiale-nom-famille"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-nom-famille"
                      v-model="filialeToAdd.nom_famille"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Couleur"
                    class="nput-modal-champ col-2 pl-0"
                  >
                    <v-input-colorpicker
                      colorpick-eyedropper-active="false"
                      class="color_picker_width ml-2"
                      v-model="filialeToAdd.couleur"
                    />
                  </b-form-group>
                </div>

                <b-form-group
                  label="Adresse *"
                  label-for="filiale-adresse"
                  class="input-modal-champ mb-0"
                >
                  <b-form-textarea
                    autocomplete="off"
                    id="filiale-adresse"
                    v-model="filialeToAdd.adresse"
                    required
                  >
                  </b-form-textarea>
                </b-form-group>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Code postal *"
                    label-for="filiale-code_postal"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-code_postal"
                      v-model="filialeToAdd.code_postal"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Ville *"
                    label-for="filiale-ville"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-ville"
                      v-model="filialeToAdd.ville"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Email *"
                    label-for="filiale-email"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-email"
                      v-model="filialeToAdd.email"
                      required
                      type="email"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Abréviation *"
                    label-for="abr"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-email"
                      v-model="filialeToAdd.abreviation"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </div>

                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Siren *"
                    label-for="filiale-siren_tva"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-siren_tva"
                      v-model="filialeToAdd.siren_value"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Tva "
                    label-for="filiale-siren_tva_value"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-siren_tva_value"
                      v-model="filialeToAdd.tva_value"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                    label="Site internet"
                    label-for="filiale-site_internet"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-site_internet"
                      v-model="filialeToAdd.site_internet"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Pays"
                    label-for="filiale-pays"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="filiale-pays"
                      v-model="filialeToAdd.pays"
                      :options="uniqueArrayOfCountries"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Banque"
                    label-for="filiale-banque"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-banque"
                      v-model="filialeToAdd.banque"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Siret"
                    label-for="filiale-siret"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-siret"
                      v-model="filialeToAdd.siret"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="BIC"
                    label-for="filiale-swift"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-swift"
                      v-model="filialeToAdd.swift"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                    label="Fax"
                    label-for="filiale-fax"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-fax"
                      v-model="filialeToAdd.fax"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <!-- <div class="rib_block mt-3"> -->
                <!-- <b-row
                      class="mb-1 "
                      v-for="(rib, index) in filialeToAdd.ribs"
                      :key="index"
                    > -->
                <!-- SWIFT && IBAN  -->
                <!-- <div class="row">
                    <div class="col-6">
                      <b-form-group
                        label="Iban *"
                        label-for="iban-template-input"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="iban-template-input"
                          v-model="filialeToAdd.ribs.iban"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group
                        label="Swift *"
                        label-for="swift-template-input"
                        class="input-modal-champ"
                      >
                        <b-form-input
                          autocomplete="off"
                          id="swift-template-input"
                          v-model="filialeToAdd.ribs.swift"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div> -->
                <div class="ModelCol mr-3">
                  <b-form-group
                    label="Téléphone"
                    label-for="filiale-telephone"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-telephone"
                      v-model="filialeToAdd.telephone"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="filialeToAdd && filialeToAdd.type === 'particulier'"
                    label="Téléphone portable"
                    label-for="filiale-telephone_portable"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="filiale-telephone_portable"
                      v-model="filialeToAdd.telephone_portable"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Description"
                    label-for="filiale-description"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-textarea
                      autocomplete="off"
                      id="filiale-description"
                      v-model="filialeToAdd.description"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </div>
                <template v-if="filialeToAdd.type !== 'master filiale'">
                  <b-form-group
                    label="Master filiales"
                    label-for="filiale-swift"
                    class="input-modal-champ mb-0"
                  >
                    <div class="rib_block">
                      <div
                        class="button-add-prop-filiale-th"
                        v-if="computedGetLastDateInList !== null"
                      >
                        <b-button
                          variant="success"
                          class="bien-add-button ml-2"
                          @click="addNewMasterFilial('add')"
                        >
                          <font-awesome-icon icon="plus" /> Ajouter un Master
                          Filiale
                        </b-button>
                      </div>
                      <b-row
                        class="mb-1"
                        v-for="(master, index) in filialeToAdd.masterFiliales"
                        :key="index"
                      >
                        <!-- SWIFT && IBAN  -->
                        <b-col col xs="3" sm="3" md="3">
                          <b-form-group
                            label="Nom master filiale *"
                            label-for="iban-template-input"
                            class="input-modal-champ"
                          >
                            <b-form-select
                              autocomplete="off"
                              id="iban-template-input"
                              v-model="master.master_id"
                              :options="computedgetMasterFilialsList"
                              text-field="name"
                              value-field="master_id"
                              class="b-form-select-raduis"
                              :disabled="index > 0"
                              required
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="4" sm="4" md="4">
                          <b-form-group
                            label="Date début"
                            label-for="fin"
                            class="input-modal-champ mb-0 pl-0"
                          >
                            <date-picker
                              value-type="format"
                              type="date"
                              required
                              v-model="master.date_debut"
                              :clear-button="true"
                              :disabled-date="
                                date => disabledStartDate(date, index)
                              "
                              :disabled="index > 0"
                            ></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="4" sm="4" md="4">
                          <b-form-group
                            label="Date fin"
                            label-for="fin"
                            class="input-modal-champ mb-0"
                          >
                            <date-picker
                              value-type="format"
                              type="date"
                              v-model="master.date_fin"
                              :clear-button="true"
                              :disabled-date="
                                date => disabledEndDate(date, index)
                              "
                              :disabled="index > 0"
                            ></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="1" sm="1" md="1">
                          <font-awesome-icon
                            icon="times"
                            class="time-icon-style"
                            @click="deleteMasterFilial(index, 'add')"
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-group>
                </template>

                <b-form-group
                  v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                  label="Support"
                  label-for="filiale-responsable"
                  class="vue-select-modal-champ"
                >
                  <multiselect
                    v-model="filialeToAdd.responsables"
                    :options="getResponsableFilialeFreeTh"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="full_name"
                    track-by="id"
                    :preselect-first="false"
                    class="select-vue-component-style mb-4"
                    :showLabels="false"
                  >
                    <span slot="noResult">Aucune responsable trouvé.</span>
                    <span slot="noOptions">Aucune responsable trouvé.</span>
                  </multiselect>

                  <b-form-group
                    v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                    label="Logo Filiale"
                    label-for="file"
                    class="input-modal-champ-file mb-0"
                  >
                  </b-form-group>
                  <div class="body-box-rapport body-box-setting">
                    <div class="doc-list mt-1 box-upload">
                      <div class="content-files">
                        <b-form-file
                          v-model="filialeToAdd.image"
                          placeholder=""
                          drop-placeholder=""
                          accept="image/*"
                          @change="previewImage"
                        ></b-form-file>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <div
                  class="ModelCol checkbox-style-type mt-3"
                  v-if="filialeToAdd && filialeToAdd.type !== 'particulier'"
                >
                  <b-form-checkbox
                    v-model="filialeToAdd.volant"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false"
                    class="check-th"
                  >
                    VOLANT
                  </b-form-checkbox>
                  <b-form-group
                    v-if="filialeToAdd.volant == true"
                    label="Responsable de dépôt"
                    label-for="depot_id_volant"
                    class="input-modal-champ mt-1"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="depot_id_volant"
                      v-model="filialeToAdd.responsable_depot"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>

                <b-form-group
                  v-if="
                    filialeToAdd.volant == false &&
                      filialeToAdd &&
                      filialeToAdd.type !== 'particulier'
                  "
                  label="Dépôt"
                  label-for="filiale-depots"
                  class="vue-select-modal-champ"
                >
                  <multiselect
                    v-model="filialeToAdd.depots"
                    :options="computedGetDepotsTh"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="depot"
                    track-by="id"
                    :preselect-first="false"
                    class="select-vue-component-style mb-4"
                    :showLabels="false"
                  >
                    <span slot="noResult">Aucune dépot trouvé.</span>
                    <span slot="noOptions">Aucune dépot trouvé.</span>
                  </multiselect>
                </b-form-group>
                <div
                  v-show="
                    filialeToAdd.volant == false &&
                      filialeToAdd &&
                      filialeToAdd.type !== 'particulier'
                  "
                >
                  <div
                    class="ModelCol"
                    v-for="depot in filialeToAdd.depots"
                    :key="'depot' + depot.id"
                  >
                    <span class="labes-depot-name">{{ depot.depot }}</span>
                    <b-form-group
                      label="Responsable de dépôt"
                      label-for="depot_id "
                      class="input-modal-champ mt-1"
                    >
                      <b-form-input
                        autocomplete="off"
                        id="depot_id"
                        v-model="depot.responsable_depot"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="body-box-rapport body-box-setting">
                    <div class="doc-list mt-1 box-upload">
                      <div class="form-type">
                        <div class="form-groupe">
                          <div class="content-files">
                            <b-form-file
                              v-model="filialeToAdd.files"
                              ref="file-type"
                              :required="false"
                              placeholder="Aucun fichier selectionné"
                              drop-placeholder="Drop file here..."
                              multiple
                            >
                            </b-form-file>
                          </div>

                          <div
                            class="file-header-titles mt-3"
                            v-if="filialeToAdd.files"
                          >
                            <div class="file-name-title">Fichiers</div>
                            <div class="file-category-title">Nom</div>
                          </div>
                          <div id="upload-file-component">
                            <div
                              class="files-to-upload"
                              v-for="(file, index) in filialeToAdd.files"
                              :key="'file' + index"
                            >
                              <p class="file-name">
                                <font-awesome-icon
                                  icon="paperclip"
                                  class="file-upload-icon"
                                />
                                {{ file.name }}
                              </p>
                              <b-form-group
                                label="Nom fichier"
                                label-for="name_fichier"
                                class="input-modal-champ mb-0"
                              >
                                <b-form-input
                                  autocomplete="off"
                                  id="name_fichier"
                                  v-model="filialeToAdd.files[index].name_file"
                                  required
                                ></b-form-input>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style mt-3">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <!-- end Add new filiale Modal -->
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>

    <!-- Delete filiale Modal -->
    <b-modal
      ref="deleteFilialeModal"
      id="deleteFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une filiale</div>
        <div class="iconClose" @click.prevent="hideModal('deleteFilialeModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerFiliale"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cette filiale
            <strong>
              {{ filialeToDelete ? filialeToDelete.name : '-' }}
            </strong>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="getLoadingSettingFilialeTh" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- end Delete filiale Modal -->

    <div class="box-all-filter mb-2">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Type</span>
        </div>
        <b-form-select
          class="b-form-select-new-style width-filter"
          v-model="typeFilter"
          :options="optionsTypeForFitre"
          @change="onChangeType"
        ></b-form-select>
      </div>
      <div class="box-label-champ mr-2" v-if="typeFilter === 'professionnel'">
        <div class="label-box-style width-filter">
          <span class="title-tabel">Type de société</span>
        </div>

        <b-form-select
          class="b-form-select-new-style width-filter"
          v-model="typeSte"
          :options="optionTypeSte"
          value-field="value"
          text-field="text"
          @change="onChangeTypeSte"
        ></b-form-select>
      </div>
      <div>
        <div
          v-if="getLoadingSettingFilialeTh"
          class="chargement-message-calender ml-2"
        >
          Chargement en cours ...
        </div>
        <div class="error-message">
          <div v-if="getErrorSettingFilialeTh" class="error">
            <ul v-if="Array.isArray(getErrorSettingFilialeTh)">
              <li v-for="(e, index) in getErrorSettingFilialeTh" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSettingFilialeTh }}</div>
          </div>
        </div>
      </div>
      <div class="box-end-position">
        <search
          :value="searchValue"
          @changeSearchValue="changeSearchValue"
          classToUse="search-style"
        />
        <b-button
          class="active-user-icon ml-2 mr-2"
          variant="outline-secondary"
          @click="handlClickActive('no')"
          pill
          :class="{ 'button-desactiver ': deleted === 'yes' }"
          :pressed="deleted === 'yes'"
          ><font-awesome-icon icon="store" />
        </b-button>
        <b-button
          class="deleted-user-icon"
          variant="outline-secondary"
          @click="handlClickActive('yes')"
          :class="{ 'button-desactiver': deleted === 'no' }"
          :pressed="deleted === 'no'"
          pill
        >
          <font-awesome-icon icon="store-slash"
        /></b-button>
      </div>
    </div>
    <div class="table-rapport-style w-100-p">
      <b-table-simple class="table-fiche m-0 p-0" responsive>
        <b-tbody>
          <b-tr class="title-ligne hearder-sticky">
            <b-th class="newcolor">Societé</b-th>
            <b-th class="newcolor" v-if="isActive">Logo</b-th>
            <b-th class="newcolor">Type de Societé</b-th>
            <template v-if="isActive">
              <b-th class="newcolor">Prénom</b-th>
              <b-th class="newcolor">Nom de famille</b-th></template
            >

            <b-th class="newcolor">Abréviation</b-th>
            <b-th class="newcolor">Type</b-th>
            <b-th class="newcolor" v-if="isActive">Adresse</b-th>
            <b-th class="newcolor">Email</b-th>
            <template v-if="isActive">
              <b-th class="newcolor">Siret</b-th>
              <b-th class="newcolor">Support</b-th>
              <b-th class="newcolor">Dépôt</b-th>
              <b-th class="newcolor">Responsable de dépôt </b-th>
              <b-th class="newcolor">Civilité</b-th>
              <b-th class="newcolor">Siren</b-th>
              <b-th class="newcolor">Tva </b-th>
              <b-th class="newcolor">Code postal</b-th>
              <b-th class="newcolor">Site internet</b-th>
              <b-th class="newcolor">Ville</b-th>
              <b-th class="newcolor">Pays</b-th>
              <b-th class="newcolor">Iban</b-th>
              <b-th class="newcolor">Banque</b-th>
              <b-th class="newcolor">BIC</b-th>
              <b-th class="newcolor">Fax</b-th>
              <b-th class="newcolor">Téléphone</b-th>
              <b-th class="newcolor">Téléphone portable</b-th>
              <b-th class="newcolor">Description</b-th>
            </template>
            <b-th class="newcolor actions"></b-th>
          </b-tr>
          <template v-for="item in computedSociete">
            <template>
              <b-tr
                v-if="item && item.depots && item.depots.length == 0"
                :key="'filiale' + item.id"
              >
                <b-td>{{ item.type != 'particulier' ? item.name : '-' }}</b-td>
                <b-td v-if="isActive">
                  <div class="mt-2" v-if="item && item.logo">
                    <img class="image-preview" :src="item.logo" />
                  </div>
                </b-td>
                <b-td>{{ item.type_societe ? item.type_societe : '-' }}</b-td>
                <template v-if="isActive">
                  <b-td>{{ item.prenom ? item.prenom : '-' }}</b-td>
                  <b-td>{{ item.nom_famille ? item.nom_famille : '-' }}</b-td>
                </template>
                <b-td>{{ item.abreviation ? item.abreviation : '-' }}</b-td>
                <b-td>{{ item.type }}</b-td>

                <b-td v-if="isActive">{{ item.adresse }}</b-td>

                <b-td>{{ item.email }}</b-td>
                <template v-if="isActive">
                  <b-td>{{ item.siret }}</b-td>
                  <b-td>{{
                    item.responsable ? item.responsable.name : '-'
                  }}</b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td>{{ item.civilite ? item.civilite : '-' }}</b-td>
                  <b-td>{{ item.siren_value }}</b-td>
                  <b-td>{{ item.tva_value }}</b-td>
                  <b-td>{{ item.code_postal }}</b-td>
                  <b-td>{{
                    item.site_internet ? item.site_internet : '-'
                  }}</b-td>
                  <b-td>{{ item.ville }}</b-td>
                  <b-td>{{ item.pays }}</b-td>
                  <b-td>{{ item.iban }}</b-td>
                  <b-td>{{ item.banque }}</b-td>
                  <b-td>{{ item.swift }}</b-td>
                  <b-td>{{ item.fax ? item.fax : '-' }}</b-td>
                  <b-td>{{ item.telephone }}</b-td>
                  <b-td>{{
                    item.telephone_portable ? item.telephone_portable : '-'
                  }}</b-td>
                  <b-td>{{ item.description ? item.description : '-' }}</b-td>
                </template>
                <b-td class="actions">
                  <template v-if="isActive"
                    ><b-button
                      :id="`popover-target-${item.id}`"
                      class="button-settings-popover"
                    >
                      <font-awesome-icon icon="wrench"
                    /></b-button>
                    <b-popover
                      :target="`popover-target-${item.id}`"
                      triggers="focus"
                      placement="top"
                      custom-class="custem-popover-societe"
                    >
                      <b-button
                        class="button-info-style ml-1"
                        size="sm"
                        variant="success"
                        title="Modification"
                        @click.prevent.stop="addDepotToFiliale(item)"
                      >
                        <font-awesome-icon icon="pencil-alt" />
                      </b-button>
                      <b-button
                        class="button-info-style ml-1"
                        size="sm"
                        variant="warning"
                        @click.prevent.stop="sequencesFacturesFiliale(item)"
                        title="Séquences factures"
                      >
                        <font-awesome-icon icon="list" />
                      </b-button>
                      <b-button
                        class="button-info-style ml-1"
                        size="sm"
                        variant="secondary"
                        @click.prevent.stop="ribFiliale(item)"
                        title="Ribs"
                      >
                        <font-awesome-icon icon="dollar-sign" />
                      </b-button>
                      <b-button
                        style="background-color: #f2929a; border-color: #f2929a"
                        class="button-mail-style ml-1"
                        size="sm"
                        @click.prevent.stop="adresseMail(item)"
                        title="Adresse Emails"
                      >
                        <font-awesome-icon icon="at" />
                      </b-button>

                      <b-button
                        class="button-info-style ml-1"
                        size="sm"
                        variant="secondary"
                        @click.prevent.stop="biensFiliale(item)"
                        title="Biens"
                      >
                        <font-awesome-icon icon="building" />
                      </b-button>
                      <b-button
                        v-if="item.type === 'master filiale'"
                        class="button-info-style ml-1"
                        size="sm"
                        variant="success"
                        title="Master filiales"
                        @click="openMasterFlilialsModal(item.id)"
                      >
                        <font-awesome-icon icon="bars" />
                      </b-button>
                      <b-button
                        class="button-info-style ml-1"
                        size="sm"
                        variant="info"
                        title="Duplication"
                        @click.prevent.stop="duplicateFiliale(item)"
                      >
                        <font-awesome-icon icon="copy" />
                      </b-button>
                      <b-button
                        class="button-danger-style ml-1"
                        size="sm"
                        variant="danger"
                        title="Supprission"
                        @click.prevent.stop="deleteFiliale(item)"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                      <b-button
                        style="background-color:#8d8cb7 ; border-color : #8d8cb7 ;"
                        class="button-info-style ml-1 "
                        size="sm"
                        title="Pseudo"
                        @click.prevent.stop="addPseudoFiliale(item)"
                      >
                        <font-awesome-icon icon="closed-captioning" />
                      </b-button>
                      <b-button
                        class="button-info-style ml-1"
                        size="sm"
                        variant="success"
                        @click.prevent.stop="filialeSocieteFacture(item)"
                        title="Societe facture"
                      >
                        <font-awesome-icon icon="list" />
                      </b-button>
                    </b-popover>
                  </template>
                  <b-button
                    v-if="!isActive"
                    class="button-info-style ml-1"
                    size="sm"
                    variant="warning"
                    @click.prevent.stop="handleClickRestaurer(item)"
                  >
                    <font-awesome-icon icon="arrow-alt-circle-left" />
                  </b-button>
                </b-td>
              </b-tr>
              <template v-else>
                <b-tr
                  v-for="(depot, index) in item.depots"
                  :key="'filiale' + depot.id + index + item.id"
                >
                  <b-td>{{ item.name ? item.name : '-' }}</b-td>
                  <b-td v-if="isActive">
                    <img
                      class="image-preview"
                      :src="item.logo"
                      v-if="item && item.logo"
                    />
                  </b-td>
                  <b-td>{{ item.type_societe ? item.type_societe : '-' }}</b-td>
                  <template v-if="isActive"
                    ><b-td>{{ item.prenom ? item.prenom : '-' }}</b-td>
                    <b-td>{{ item.nom_famille ? item.nom_famille : '-' }}</b-td>
                  </template>
                  <b-td>{{ item.abreviation ? item.abreviation : '-' }}</b-td>
                  <b-td>{{ item.type }}</b-td>
                  <b-td v-if="isActive">{{ item.adresse }}</b-td>
                  <b-td>{{ item.email }}</b-td>
                  <template v-if="isActive">
                    <b-td>{{ item.siret }}</b-td>
                    <b-td>{{
                      item.responsable ? item.responsable.name : '-'
                    }}</b-td>

                    <b-td
                      ><span>{{ depot.depot }} </span>
                      <b-button
                        class="button-danger-style border-raduis ml-2"
                        size="sm"
                        variant="danger"
                        @click.prevent.stop="deleteFilialeDepot(item, depot)"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </b-td>

                    <b-td>
                      <input
                        class="input-focus-responsable-depot"
                        placeholder="Nom de responsable dépot"
                        @change="updateNomResponsableDepot($event, item, depot)"
                        title="Modifier
                  le nom de responsable  dépôt"
                        :value="depot.responsable_depot"
                      />
                    </b-td>
                    <b-td>{{ item.civilite ? item.civilite : '-' }}</b-td>
                    <b-td>{{ item.siren_value }}</b-td>
                    <b-td>{{ item.tva_value }}</b-td>
                    <b-td>{{ item.code_postal }}</b-td>
                    <b-td>{{ item.site_internet }}</b-td>
                    <b-td>{{ item.ville }}</b-td>
                    <b-td>{{ item.pays }}</b-td>
                    <b-td>{{ item.iban }}</b-td>
                    <b-td>{{ item.banque }}</b-td>
                    <b-td>{{ item.swift }}</b-td>
                    <b-td>{{ item.fax ? item.fax : '-' }}</b-td>
                    <b-td>{{ item.telephone }}</b-td>
                    <b-td>{{
                      item.telephone_portable ? item.telephone_portable : '-'
                    }}</b-td>
                    <b-td>{{ item.description ? item.description : '-' }}</b-td>
                  </template>
                  <b-td class="actions">
                    <template v-if="isActive"
                      ><b-button
                        :id="`popover-target-${item.id}`"
                        class="button-settings-popover"
                      >
                        <font-awesome-icon icon="wrench"
                      /></b-button>
                      <b-popover
                        :target="`popover-target-${item.id}`"
                        triggers="focus"
                        placement="top"
                        custom-class="custem-popover-societe"
                      >
                        <b-button
                          class="button-info-style ml-1"
                          size="sm"
                          variant="success"
                          title="Modification"
                          @click.prevent.stop="addDepotToFiliale(item)"
                        >
                          <font-awesome-icon icon="pencil-alt" />
                        </b-button>
                        <b-button
                          class="button-info-style ml-1"
                          size="sm"
                          variant="warning"
                          @click.prevent.stop="sequencesFacturesFiliale(item)"
                          title="Séquences factures"
                        >
                          <font-awesome-icon icon="list" />
                        </b-button>
                        <b-button
                          class="button-info-style ml-1"
                          size="sm"
                          variant="secondary"
                          @click.prevent.stop="ribFiliale(item)"
                          title="Ribs"
                        >
                          <font-awesome-icon icon="dollar-sign" />
                        </b-button>
                        <b-button
                          style="background-color: #f2929a; border-color: #f2929a"
                          class="button-mail-style ml-1"
                          size="sm"
                          @click.prevent.stop="adresseMail(item)"
                          title="Adresse Emails"
                        >
                          <font-awesome-icon icon="at" />
                        </b-button>

                        <b-button
                          class="button-info-style ml-1"
                          size="sm"
                          variant="secondary"
                          @click.prevent.stop="biensFiliale(item)"
                          title="Biens"
                        >
                          <font-awesome-icon icon="building" />
                        </b-button>
                        <b-button
                          v-if="item.type === 'master filiale'"
                          class="button-info-style ml-1"
                          size="sm"
                          variant="success"
                          title="Master filiales"
                          @click="openMasterFlilialsModal(item.id)"
                        >
                          <font-awesome-icon icon="bars" />
                        </b-button>
                        <b-button
                          class="button-info-style ml-1"
                          size="sm"
                          variant="info"
                          title="Duplication"
                          @click.prevent.stop="duplicateFiliale(item)"
                        >
                          <font-awesome-icon icon="copy" />
                        </b-button>
                        <b-button
                          class="button-danger-style ml-1"
                          size="sm"
                          variant="danger"
                          title="Supprission"
                          @click.prevent.stop="deleteFiliale(item)"
                        >
                          <font-awesome-icon icon="trash" />
                        </b-button>
                        <b-button
                          style="background-color:#8d8cb7 ; border-color : #8d8cb7 ;"
                          class="button-info-style ml-1 "
                          size="sm"
                          title="Pseudo"
                          @click.prevent.stop="addPseudoFiliale(item)"
                        >
                          <font-awesome-icon icon="closed-captioning" />
                        </b-button>
                        <b-button
                          class="button-info-style ml-1"
                          size="sm"
                          variant="success"
                          @click.prevent.stop="filialeSocieteFacture(item)"
                          title="Societe facture"
                        >
                          <font-awesome-icon icon="list" />
                        </b-button>
                      </b-popover>
                    </template>
                    <b-button
                      v-if="!isActive"
                      class="button-info-style ml-1"
                      size="sm"
                      variant="warning"
                      @click.prevent.stop="handleClickRestaurer(item)"
                    >
                      <font-awesome-icon icon="arrow-alt-circle-left" />
                    </b-button>
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
          <b-tr v-if="getSettingFilialesTh.length == 0">
            <b-td colspan="28"> Il n'y a aucun enregistrement à afficher</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div class="footer-style">
      <b-pagination
        v-model="page"
        :total-rows="getTotalFilile"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        align="center"
        size="sm"
        @change="pagination"
        class="pagination-style"
      ></b-pagination>

      <div class="per-page-element-style">
        <div class="box-label-champ">
          <div class="label-box-style">
            <span class="title-tabel">Eléments par page</span>
          </div>
        </div>
        <b-form-select
          v-model="perPage"
          :options="perPageList"
          @change="changePerPage"
          class="b-form-select-new-style bg-select"
        ></b-form-select>
      </div>
    </div>

    <!-- Update filiale Modal -->
    <b-modal
      ref="updateFilialeModal"
      id="updateFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier une société</div>
        <div class="iconClose" @click.prevent="hideModal('updateFilialeModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body v-if="filialeToUpdate && filialeToUpdate != null">
          <form
            @submit.prevent="handleSubmitUpdateFiliale"
            class="form-modal-custom-style"
          >
            <template v-if="filialeToUpdate && filialeToUpdate != null">
              <div class="row">
                <div class="col-6">
                  <div
                    class="ModelCol checkbox-style-type mt-3"
                    v-if="
                      filialeToUpdate && filialeToUpdate.type !== 'particulier'
                    "
                  >
                    <div
                      class="mt-2 d-flex justify-content-center"
                      v-if="filialeToUpdate && filialeToUpdate.logo"
                    >
                      <img
                        class="image-preview-inside"
                        :src="filialeToUpdate.logo"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <b-form-group
                    label="Type de société"
                    label-for="type_societe"
                    class="input-modal-champ mt-1 col-4"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="type-societe"
                      v-model="filialeToUpdate.type"
                      required
                      :options="options"
                      class="b-form-select-raduis"
                      :disabled="filialeToUpdate.type === 'master filiale'"
                    ></b-form-select>
                  </b-form-group>

                  <div
                    class="ModelCol checkbox-style-type mt-3"
                    v-if="
                      filialeToUpdate && filialeToUpdate.type !== 'particulier'
                    "
                  >
                    <b-form-checkbox
                      v-model="filialeToUpdate.is_societe_support"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                      class="check-th"
                    >
                      Support
                    </b-form-checkbox>
                    <b-form-group
                      v-if="filialeToUpdate.is_societe_support == true"
                      label="Type de société support"
                      label-for="filiale-type_societe-support"
                      class="input-modal-champ mt-1"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-type_societe-support"
                        v-model="filialeToUpdate.type_societe_support"
                        required
                        :options="computedGetTypeSocieteSupportTh"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div
                class="ModelCol checkbox-style-type mt-3"
                v-if="filialeToUpdate && filialeToUpdate.type !== 'particulier'"
              >
                <b-form-group
                  label="Nom de société"
                  label-for="filiale-name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-name"
                    v-model="filialeToUpdate.name"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type de société"
                  label-for="filiale-name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-name"
                    v-model="filialeToUpdate.type_societe"
                    :options="typeSteFilter"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Couleur"
                  class="nput-modal-champ col-2 pl-0"
                >
                  <v-input-colorpicker
                    colorpick-eyedropper-active="false"
                    class="color_picker_width ml-2"
                    v-model="filialeToUpdate.couleur"
                  />
                </b-form-group>
              </div>
              <div
                class="ModelCol mr-3"
                v-if="filialeToUpdate && filialeToUpdate.type === 'particulier'"
              >
                <b-form-group
                  label="Civilité"
                  label-for="filiale-civilite"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-civilite"
                    v-model="filialeToUpdate.civilite"
                    :options="genreList"
                    class="b-form-select-raduis"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Prénom"
                  label-for="filiale-prenom"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-prenom"
                    v-model="filialeToUpdate.prenom"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Nom de famille"
                  label-for="filiale-nom-famille"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-nom-famille"
                    v-model="filialeToUpdate.nom_famille"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Couleur"
                  class="nput-modal-champ col-2 pl-0"
                >
                  <v-input-colorpicker
                    colorpick-eyedropper-active="false"
                    class="color_picker_width ml-2"
                    v-model="filialeToUpdate.couleur"
                  />
                </b-form-group>
              </div>

              <b-form-group
                label="Adresse"
                label-for="filiale-adresse"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  autocomplete="off"
                  id="filiale-adresse"
                  v-model="filialeToUpdate.adresse"
                >
                </b-form-textarea>
              </b-form-group>
              <div class="ModelCol mr-3">
                <b-form-group
                  v-if="filialeToUpdate"
                  label="Code postal"
                  label-for="filiale-code_postal"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-code_postal"
                    v-model="filialeToUpdate.code_postal"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="filialeToUpdate"
                  label="Ville"
                  label-for="filiale-ville"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-ville"
                    v-model="filialeToUpdate.ville"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="ModelCol mr-3">
                <b-form-group
                  label="Email"
                  label-for="filiale-email"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-email"
                    v-model="filialeToUpdate.email"
                    required
                    type="email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Abréviation"
                  label-for="abr"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-email"
                    v-model="filialeToUpdate.abreviation"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <!-- new property  -->

              <div class="ModelCol mr-3">
                <b-form-group
                  label="Siren "
                  label-for="filiale-siren_tva"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-siren_value"
                    v-model="filialeToUpdate.siren_value"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Tva "
                  label-for="filiale-siren_tva_value"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-tva_value"
                    v-model="filialeToUpdate.tva_value"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Site internet"
                  label-for="filiale-site_internet"
                  class="input-modal-champ mb-0"
                  v-if="
                    filialeToUpdate && filialeToUpdate.type !== 'particulier'
                  "
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-site_internet"
                    v-model="filialeToUpdate.site_internet"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Pays"
                  label-for="filiale-pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-pays"
                    v-model="filialeToUpdate.pays"
                    :options="uniqueArrayOfCountries"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Banque"
                  label-for="filiale-banque"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-banque"
                    v-model="filialeToUpdate.banque"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Siret"
                  label-for="filiale-siret"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-siret"
                    v-model="filialeToUpdate.siret"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="BIC"
                  label-for="filiale-swift"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-swift"
                    v-model="filialeToUpdate.swift"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="
                    filialeToUpdate && filialeToUpdate.type !== 'particulier'
                  "
                  label="Fax"
                  label-for="filiale-fax"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-fax"
                    v-model="filialeToUpdate.fax"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Téléphone"
                  label-for="filiale-telephone"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-telephone"
                    v-model="filialeToUpdate.telephone"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="
                    filialeToUpdate && filialeToUpdate.type === 'particulier'
                  "
                  label="Téléphone portable"
                  label-for="filiale-telephone_portable"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-telephone_portable"
                    v-model="filialeToUpdate.telephone_portable"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Description"
                  label-for="filiale-description"
                  class="input-modal-champ mb-0"
                >
                  <b-form-textarea
                    autocomplete="off"
                    id="filiale-description"
                    v-model="filialeToUpdate.description"
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
              <template v-if="filialeToUpdate.type !== 'master filiale'">
                <b-form-group
                  label="Master filiales"
                  label-for="filiale-swift"
                  class="input-modal-champ mb-0"
                >
                  <div class="rib_block">
                    <div class="button-add-prop-filiale-th">
                      <b-button
                        variant="success"
                        class="bien-add-button ml-2"
                        @click="addNewMasterFilial('update')"
                        v-if="computedGetLastDateInListUpdate !== null"
                      >
                        <font-awesome-icon icon="plus" /> Ajouter un Master
                        Filiale
                      </b-button>
                    </div>
                    <b-row
                      class="mb-1"
                      v-for="(master, index) in filialeToUpdate.masterFiliales"
                      :key="index"
                    >
                      <!-- SWIFT && IBAN  -->
                      <b-col col xs="3" sm="3" md="3">
                        <b-form-group
                          label="Nom master filial *"
                          label-for="iban-template-input"
                          class="input-modal-champ"
                        >
                          <b-form-select
                            autocomplete="off"
                            id="iban-template-input"
                            v-model="master.master_id"
                            :options="computedgetMasterFilialsList"
                            text-field="name"
                            value-field="master_id"
                            class="b-form-select-raduis"
                            :disabled="index > 0"
                            required
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="4" sm="4" md="4">
                        <b-form-group
                          label="Date début"
                          label-for="fin"
                          class="input-modal-champ mb-0 pl-0"
                        >
                          <date-picker
                            value-type="format"
                            type="date"
                            required
                            v-model="master.date_debut"
                            :clear-button="true"
                            :disabled-date="
                              date => disabledStartDateUpdate(date, index)
                            "
                            :disabled="index > 0"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="4" sm="4" md="4">
                        <b-form-group
                          label="Date fin"
                          label-for="fin"
                          class="input-modal-champ mb-0"
                        >
                          <date-picker
                            value-type="format"
                            type="date"
                            v-model="master.date_fin"
                            :clear-button="true"
                            :disabled-date="
                              date => disabledEndDateUpdate(date, index)
                            "
                            :disabled="index > 0"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="1" sm="1" md="1">
                        <font-awesome-icon
                          icon="times"
                          class="time-icon-style"
                          @click="deleteMasterFilial(index, 'update')"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </template>
              <b-form-group
                label="Support"
                label-for="filiale-responsable"
                class="vue-select-modal-champ"
              >
                <multiselect
                  v-model="filialeToUpdate.responsables"
                  :options="computedGetResponsableFilialeFreeTh"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="name"
                  track-by="id"
                  :preselect-first="false"
                  class="select-vue-component-style mb-4"
                  :showLabels="false"
                >
                  <span slot="noResult">Aucune responsable trouvé.</span>
                  <span slot="noOptions">Aucune responsable trouvé.</span>
                </multiselect>
              </b-form-group>
              <!-- end new property  -->
              <b-form-group
                v-if="filialeToUpdate && filialeToUpdate.type !== 'particulier'"
                label="Logo Filiale"
                label-for="file"
                class="input-modal-champ-file mb-0"
              >
                <b-form-file
                  v-model="filialeToUpdate.image"
                  placeholder=""
                  drop-placeholder=""
                  @change="previewImageInUpdate"
                  accept="image/*"
                ></b-form-file>
              </b-form-group>

              <div class="ModelCol checkbox-style-type mt-3">
                <b-form-checkbox
                  v-model="filialeToUpdate.volant"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  class="check-th"
                >
                  VOLANT
                </b-form-checkbox>
                <b-form-group
                  v-if="filialeToUpdate.volant == true"
                  label="Responsable de dépôt"
                  label-for="depot_id_volant"
                  class="input-modal-champ mt-1"
                >
                  <b-form-input
                    autocomplete="off"
                    id="depot_id_volant"
                    v-model="filialeToUpdate.responsable_depot"
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <b-form-group
                v-if="filialeToUpdate.volant == false"
                label="Dépôt"
                label-for="filiale-depots"
                class="vue-select-modal-champ"
              >
                <multiselect
                  v-model="filialeToUpdate.depots"
                  :options="computedGetDepotsTh"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="depot"
                  track-by="id"
                  :preselect-first="false"
                  class="select-vue-component-style mb-4"
                  :showLabels="false"
                >
                  <span slot="noResult">Aucune dépot trouvé.</span>
                  <span slot="noOptions">Aucune dépot trouvé.</span>
                </multiselect>
              </b-form-group>
              <div v-show="filialeToUpdate.volant == false">
                <div
                  class="ModelCol"
                  v-for="depot in filialeToUpdate.depots"
                  :key="'depot' + depot.id"
                >
                  <span class="labes-depot-name">{{ depot.depot }}</span>
                  <b-form-group
                    label="Responsable de dépôt"
                    label-for="depot_id "
                    class="input-modal-champ mt-1"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="depot_id"
                      v-model="depot.responsable_depot"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <!-- FILES -->
              <div class="row">
                <div class="col">
                  <div class="body-box-rapport body-box-setting mt-0">
                    <div class="doc-list mt-1 box-upload">
                      <div class="form-type">
                        <div class="form-groupe">
                          <p>Déposer vos fichiers ici</p>
                          <div class="content-files">
                            <b-form-file
                              v-model="filialeToUpdate.newFiles"
                              ref="file-type"
                              :required="false"
                              placeholder="Aucun fichier selectionné"
                              drop-placeholder="Drop file here..."
                              multiple
                            >
                            </b-form-file>
                          </div>

                          <div
                            class="file-header-titles mt-3"
                            v-if="filialeToUpdate.newFiles"
                          >
                            <div class="file-name-title">Fichiers</div>
                            <div class="file-category-title">Nom</div>
                          </div>
                          <div id="upload-file-component">
                            <div
                              class="files-to-upload"
                              v-for="(file, index) in filialeToUpdate.newFiles"
                              :key="'file' + index"
                            >
                              <p class="file-name">
                                <font-awesome-icon
                                  icon="paperclip"
                                  class="file-upload-icon"
                                />
                                {{ file.name }}
                              </p>
                              <b-form-group>
                                <b-form-input
                                  autocomplete="off"
                                  id="name_fichier"
                                  v-model="
                                    filialeToUpdate.newFiles[index].name_file
                                  "
                                  required
                                ></b-form-input>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6" v-if="filialeToUpdate.files.length > 0">
                  <b-table-simple class="table-fiche m-0 p-0" responsive>
                    <b-tbody>
                      <b-tr class="title-ligne hearder-sticky">
                        <b-th class="newcolor">Fichiers</b-th>
                        <b-th class="newcolor actions"></b-th>
                      </b-tr>
                      <template v-for="(item, index) in filialeToUpdate.files">
                        <template>
                          <b-tr :key="'file' + item.id">
                            <b-td>
                              <font-awesome-icon
                                icon="paperclip"
                                class="file-upload-icon mr-1"
                              />
                              <a :href="item.link" target="_blank">
                                {{ item.name }}
                              </a>
                            </b-td>
                            <b-td class="actions">
                              <b-button
                                class="button-danger-style ml-1"
                                size="sm"
                                variant="danger"
                                @click.prevent.stop="
                                  deleteFile(item, filialeToUpdate, index)
                                "
                              >
                                <font-awesome-icon icon="trash" />
                              </b-button>
                              <b-button
                                class="button-succes-style ml-1"
                                size="sm"
                                variant="succes"
                                @click.prevent.stop="
                                  renameFile(item, filialeToUpdate, index)
                                "
                              >
                                <font-awesome-icon icon="pencil-alt" />
                              </b-button>
                            </b-td>
                          </b-tr>
                        </template>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>

              <div class="actionModel">
                <div class="messageError">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style mt-3">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </template>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- end Update filiale Modal -->

    <!-- Duplicate filiale Modal -->
    <b-modal
      ref="duplicateFilialeModal"
      id="duplicateFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Dupliquer une société</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('duplicateFilialeModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body v-if="filialeToUpdate && filialeToUpdate != null">
          <form
            @submit.prevent="handleDuplicateFiliale"
            class="form-modal-custom-style"
          >
            <template v-if="filialeToUpdate && filialeToUpdate != null">
              <!-- <b-form-group
                class="mb-0 ml-3"
                v-slot="{ ariaDescribedby }"
                v-if="filialeToUpdate && filialeToUpdate.type"
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="filialeToUpdate.type"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group> -->
              <div class="row">
                <div class="col-6">
                  <div
                    class="ModelCol checkbox-style-type mt-3"
                    v-if="
                      filialeToUpdate && filialeToUpdate.type !== 'particulier'
                    "
                  >
                    <div
                      class="mt-2 d-flex justify-content-center"
                      v-if="filialeToUpdate && filialeToUpdate.logo"
                    >
                      <img
                        class="image-preview-inside"
                        :src="filialeToUpdate.logo"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <b-form-group
                    label="Type de société"
                    label-for="type_societe"
                    class="input-modal-champ mt-1 col-4"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="type-societe"
                      v-model="filialeToUpdate.type"
                      required
                      :options="options"
                      class="b-form-select-raduis"
                    ></b-form-select>
                  </b-form-group>

                  <div
                    class="ModelCol checkbox-style-type mt-3"
                    v-if="
                      filialeToUpdate && filialeToUpdate.type !== 'particulier'
                    "
                  >
                    <b-form-checkbox
                      v-model="filialeToUpdate.is_societe_support"
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                      class="check-th"
                    >
                      Support
                    </b-form-checkbox>
                    <b-form-group
                      v-if="filialeToUpdate.is_societe_support == true"
                      label="Type de société support"
                      label-for="filiale-type_societe-support"
                      class="input-modal-champ mt-1"
                    >
                      <b-form-select
                        autocomplete="off"
                        id="filiale-type_societe-support"
                        v-model="filialeToUpdate.type_societe_support"
                        required
                        :options="computedGetTypeSocieteSupportTh"
                        class="b-form-select-raduis"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div
                class="ModelCol checkbox-style-type mt-3"
                v-if="filialeToUpdate && filialeToUpdate.type !== 'particulier'"
              >
                <b-form-group
                  label="Nom de société"
                  label-for="filiale-name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-name"
                    v-model="filialeToUpdate.name"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Type de société"
                  label-for="filiale-name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-name"
                    v-model="filialeToUpdate.type_societe"
                    :options="typeSteFilter"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Couleur"
                  class="nput-modal-champ col-2 pl-0"
                >
                  <v-input-colorpicker
                    colorpick-eyedropper-active="false"
                    class="color_picker_width ml-2"
                    v-model="filialeToUpdate.couleur"
                  />
                </b-form-group>
              </div>
              <div
                class="ModelCol mr-3"
                v-if="filialeToUpdate && filialeToUpdate.type === 'particulier'"
              >
                <b-form-group
                  label="Civilité"
                  label-for="filiale-civilite"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-civilite"
                    v-model="filialeToUpdate.civilite"
                    :options="genreList"
                    class="b-form-select-raduis"
                    required
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Prénom"
                  label-for="filiale-prenom"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-prenom"
                    v-model="filialeToUpdate.prenom"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Nom de famille"
                  label-for="filiale-nom-famille"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-nom-famille"
                    v-model="filialeToUpdate.nom_famille"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Couleur"
                  class="nput-modal-champ col-2 pl-0"
                >
                  <v-input-colorpicker
                    colorpick-eyedropper-active="false"
                    class="color_picker_width ml-2"
                    v-model="filialeToUpdate.couleur"
                  />
                </b-form-group>
              </div>

              <b-form-group
                label="Adresse"
                label-for="filiale-adresse"
                class="input-modal-champ mb-0"
              >
                <b-form-textarea
                  autocomplete="off"
                  id="filiale-adresse"
                  v-model="filialeToUpdate.adresse"
                  required
                >
                </b-form-textarea>
              </b-form-group>
              <div class="ModelCol mr-3">
                <b-form-group
                  v-if="filialeToUpdate"
                  label="Code postal"
                  label-for="filiale-code_postal"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-code_postal"
                    v-model="filialeToUpdate.code_postal"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="filialeToUpdate"
                  label="Ville"
                  label-for="filiale-ville"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-ville"
                    v-model="filialeToUpdate.ville"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <div class="ModelCol mr-3">
                <b-form-group
                  label="Email"
                  label-for="filiale-email"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-email"
                    v-model="filialeToUpdate.email"
                    required
                    type="email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Abréviation"
                  label-for="abr"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-email"
                    v-model="filialeToUpdate.abreviation"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <!-- new property  -->

              <div class="ModelCol mr-3">
                <b-form-group
                  label="Siren "
                  label-for="filiale-siren_tva"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-siren_value"
                    v-model="filialeToUpdate.siren_value"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Tva "
                  label-for="filiale-siren_tva_value"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-tva_value"
                    v-model="filialeToUpdate.tva_value"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Site internet"
                  label-for="filiale-site_internet"
                  class="input-modal-champ mb-0"
                  v-if="
                    filialeToUpdate && filialeToUpdate.type !== 'particulier'
                  "
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-site_internet"
                    v-model="filialeToUpdate.site_internet"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Pays"
                  label-for="filiale-pays"
                  class="input-modal-champ mb-0"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-pays"
                    v-model="filialeToUpdate.pays"
                    :options="uniqueArrayOfCountries"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Banque"
                  label-for="filiale-banque"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-banque"
                    v-model="filialeToUpdate.banque"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  label="Siret"
                  label-for="filiale-siret"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-siret"
                    v-model="filialeToUpdate.siret"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="BIC"
                  label-for="filiale-swift"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-swift"
                    v-model="filialeToUpdate.swift"
                    required
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="
                    filialeToUpdate && filialeToUpdate.type !== 'particulier'
                  "
                  label="Fax"
                  label-for="filiale-fax"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-fax"
                    v-model="filialeToUpdate.fax"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="ModelCol mr-3">
                <b-form-group
                  label="Téléphone"
                  label-for="filiale-telephone"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-telephone"
                    v-model="filialeToUpdate.telephone"
                    required
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="
                    filialeToUpdate && filialeToUpdate.type === 'particulier'
                  "
                  label="Téléphone portable"
                  label-for="filiale-telephone_portable"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="filiale-telephone_portable"
                    v-model="filialeToUpdate.telephone_portable"
                    type="number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Description"
                  label-for="filiale-description"
                  class="input-modal-champ mb-0"
                >
                  <b-form-textarea
                    autocomplete="off"
                    id="filiale-description"
                    v-model="filialeToUpdate.description"
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>

              <template v-if="filialeToUpdate.type !== 'master filiale'">
                <b-form-group
                  label="Master filials"
                  label-for="filiale-swift"
                  class="input-modal-champ mb-0"
                >
                  <div class="rib_block">
                    <div class="button-add-prop-filiale-th">
                      <b-button
                        variant="success"
                        class="bien-add-button ml-2"
                        @click="addNewMasterFilial('update')"
                        v-if="computedGetLastDateInListUpdate !== null"
                      >
                        <font-awesome-icon icon="plus" /> Ajouter un Master
                        Filiale
                      </b-button>
                    </div>
                    <b-row
                      class="mb-1"
                      v-for="(master, index) in filialeToUpdate.masterFiliales"
                      :key="index"
                    >
                      <!-- SWIFT && IBAN  -->
                      <b-col col xs="3" sm="3" md="3">
                        <b-form-group
                          label="Nom master filiale *"
                          label-for="iban-template-input"
                          class="input-modal-champ"
                        >
                          <b-form-select
                            autocomplete="off"
                            id="iban-template-input"
                            v-model="master.master_id"
                            :options="computedgetMasterFilialsList"
                            text-field="name"
                            value-field="master_id"
                            class="b-form-select-raduis"
                            :disabled="index > 0"
                            required
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="4" sm="4" md="4">
                        <b-form-group
                          label="Date début"
                          label-for="fin"
                          class="input-modal-champ mb-0 pl-0"
                        >
                          <date-picker
                            value-type="format"
                            type="date"
                            required
                            v-model="master.date_debut"
                            :clear-button="true"
                            :disabled-date="
                              date => disabledStartDateUpdate(date, index)
                            "
                            :disabled="index > 0"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="4" sm="4" md="4">
                        <b-form-group
                          label="Date fin"
                          label-for="fin"
                          class="input-modal-champ mb-0"
                        >
                          <date-picker
                            value-type="format"
                            type="date"
                            v-model="master.date_fin"
                            :clear-button="true"
                            :disabled-date="
                              date => disabledEndDateUpdate(date, index)
                            "
                            :disabled="index > 0"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="1" sm="1" md="1">
                        <font-awesome-icon
                          icon="times"
                          class="time-icon-style"
                          @click="deleteMasterFilial(index, 'update')"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </template>
              <b-form-group
                label="Support"
                label-for="filiale-responsable"
                class="vue-select-modal-champ"
              >
                <multiselect
                  v-model="filialeToUpdate.responsables"
                  :options="computedGetResponsableFilialeFreeTh"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="name"
                  track-by="id"
                  :preselect-first="false"
                  required
                  class="select-vue-component-style mb-4"
                  :showLabels="false"
                >
                  <span slot="noResult">Aucune responsable trouvé.</span>
                  <span slot="noOptions">Aucune responsable trouvé.</span>
                </multiselect>
              </b-form-group>
              <!-- end new property  -->
              <b-form-group
                v-if="filialeToUpdate && filialeToUpdate.type !== 'particulier'"
                label="Logo Filiale"
                label-for="file"
                class="input-modal-champ-file mb-0"
              >
                <b-form-file
                  v-model="filialeToUpdate.image"
                  placeholder=""
                  drop-placeholder=""
                  @change="previewImageInUpdate"
                  accept="image/*"
                ></b-form-file>
              </b-form-group>

              <div class="ModelCol checkbox-style-type mt-3">
                <b-form-checkbox
                  v-model="filialeToUpdate.volant"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  class="check-th"
                >
                  VOLANT
                </b-form-checkbox>
                <b-form-group
                  v-if="filialeToUpdate.volant == true"
                  label="Responsable de dépôt"
                  label-for="depot_id_volant"
                  class="input-modal-champ mt-1"
                >
                  <b-form-input
                    autocomplete="off"
                    id="depot_id_volant"
                    v-model="filialeToUpdate.responsable_depot"
                  >
                  </b-form-input>
                </b-form-group>
              </div>

              <b-form-group
                v-if="filialeToUpdate.volant == false"
                label="Dépôt"
                label-for="filiale-depots"
                class="vue-select-modal-champ"
              >
                <multiselect
                  v-model="filialeToUpdate.depots"
                  :options="computedGetDepotsTh"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="depot"
                  track-by="id"
                  :preselect-first="false"
                  required
                  class="select-vue-component-style mb-4"
                  :showLabels="false"
                >
                  <span slot="noResult">Aucune dépot trouvé.</span>
                  <span slot="noOptions">Aucune dépot trouvé.</span>
                </multiselect>
              </b-form-group>
              <div v-show="filialeToUpdate.volant == false">
                <div
                  class="ModelCol"
                  v-for="depot in filialeToUpdate.depots"
                  :key="'depot' + depot.id"
                >
                  <span class="labes-depot-name">{{ depot.depot }}</span>
                  <b-form-group
                    label="Responsable de dépôt"
                    label-for="depot_id "
                    class="input-modal-champ mt-1"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="depot_id"
                      v-model="depot.responsable_depot"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="body-box-rapport body-box-setting">
                <div class="doc-list mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div class="content-files">
                        <b-form-file
                          v-model="filialeToUpdate.files"
                          ref="file-type"
                          accept=".pdf"
                          :required="false"
                          placeholder="Aucun fichier selectionné"
                          drop-placeholder="Drop file here..."
                          multiple
                        >
                        </b-form-file>
                      </div>

                      <div
                        class="file-header-titles mt-3"
                        v-if="filialeToUpdate.files"
                      >
                        <div class="file-name-title">Fichiers</div>
                        <div class="file-category-title">Nom</div>
                      </div>
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in filialeToUpdate.files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon"
                            />
                            {{ file.name }}
                          </p>
                          <b-form-group
                            label="Nom fichier"
                            label-for="name_fichier"
                            class="input-modal-champ mb-0"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="name_fichier"
                              v-model="filialeToUpdate.files[index].name_file"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="actionModel">
                <div class="messageError">
                  <div v-if="error" class="error">
                    <ul v-if="Array.isArray(error)">
                      <li v-for="(e, index) in error" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ error }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style mt-3">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </template>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- end Duplicate filiale Modal -->

    <!-- Delete file Modal -->
    <b-modal
      ref="deleteFileModal"
      id="deleteFileModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un fichier</div>
        <div
          class="iconClose"
          @click.prevent="hideDeleteFileModal('deleteFileModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerFile"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer ce fichier
            <strong>
              {{ fileToDelete ? fileToDelete.name : '-' }}
            </strong>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="getLoadingSettingFilialeTh" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- end Delete file Modal -->

    <!-- Rename file Modal -->
    <b-modal
      ref="renameFileModal"
      id="renameFileModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">
          Renommer le fichier {{ fileToRename.name }}
        </div>
        <div
          class="iconClose"
          @click.prevent="hideDeleteFileModal('renameFileModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form @submit.prevent="handleRenameFile" class="form-modal-custom-style">
        <div class="mt-4 ml-3">
          <b-form-group
            label="Nom fichier"
            label-for="nom_fichier"
            class="input-modal-champ mb-0"
          >
            <b-form-input
              autocomplete="off"
              id="nom_fichier"
              v-model="inputFileToRename"
              type="text"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="getLoadingSettingFilialeTh" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- end Rename file Modal -->

    <!-- Séquences factures filiale Modal -->
    <b-modal
      ref="sequencesFacturesFilialeModal"
      id="sequencesFacturesFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Gestion des séquences factures</div>
        <div
          class="iconClose"
          @click.prevent="hideSequencesModal(filialeToUpdate)"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleAddSequance(filialeToUpdate)"
            class="form-modal-custom-style"
          >
            <div v-if="getSequanceLoder" class="message text-aligne-center">
              <div
                class="loading-custom-template-block style-half-circle-loder"
              >
                <Half-circle-spinner
                  :animation-duration="1000"
                  :size="50"
                  :color="'#4d4bac'"
                  class="loeder"
                />
              </div>
            </div>
            <div v-else>
              <template>
                <!-- Begin séquences factures -->
                <b-form-group
                  label-for="filiale-sequences-factures"
                  class="input-modal-champ mb-0 prop-socie-block"
                >
                  <div class="row m-auto">
                    <b-row class="row m-auto mb-1 w-100">
                      <!-- Fields -->
                      <b-row class="w-100">
                        <b-col class="d-flex"
                          ><div class="mb-1 ml-3 titleFields mt-2">
                            Champs dynamiques
                            <div class="CopieMsg" v-if="copierText">
                              Text copier
                            </div>
                          </div>

                          <b-row class="row mb-1 ml-3 placeholders">
                            <div
                              v-for="(prop, val) of getSequencesFacturesFields"
                              :key="prop.id"
                              @click="CopyFields({ prop: prop, val: val })"
                              class="filed-class"
                              title="Copier"
                            >
                              <div class="icon-field">☰</div>
                              <div class="value-field">
                                {{ val }}
                              </div>
                              <div class="icon-copy">
                                <font-awesome-icon icon="copy" />
                              </div>
                            </div> </b-row
                        ></b-col>
                        <b-col class="action-add-prop"
                          ><div class="button-add-prop-filiale-th">
                            <b-button
                              variant="success"
                              class="bien-add-button ml-2"
                              @click="addLineSequenceFactureFiliale()"
                            >
                              <font-awesome-icon icon="plus" /> Ajouter une
                              séquence
                            </b-button>
                          </div></b-col
                        >
                      </b-row>
                    </b-row>
                    <b-row
                      class="row mb-1 sequences_block"
                      v-for="(sequences, index) in getSequancesofFilile"
                      :key="index"
                    >
                      <div
                        class="action-icon cancel"
                        v-if="
                          (sequences[0].last_num == 0 &&
                            sequences[1].last_num == 0 &&
                            sequences[2].last_num == 0 &&
                            sequences[3].last_num == 0) ||
                            (sequences[0].last_num == null &&
                              sequences[1].last_num == null &&
                              sequences[2].last_num == null &&
                              sequences[3].last_num == null)
                        "
                      >
                        <font-awesome-icon
                          icon="times"
                          class="fa-2x bell1 delete-prop-societe"
                          @click="
                            DeleteLineSequenceFactureFiliale(
                              index,
                              sequences[0]
                            )
                          "
                        />
                      </div>
                      <b-row
                        class="mb-1"
                        v-for="(sequence, index2) in sequences"
                        :key="index2"
                        style="margin: 0"
                      >
                        <!-- Séquences factures  -->
                        <b-col col xs="12" sm="2" md="2">
                          <b-form-group
                            :label="sequence.title + ' *'"
                            label-for="sequence-facture-format-input"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="sequence-facture-format-input"
                              v-model="sequence.sequence"
                              required
                              :disabled="
                                sequences[0].last_num != '0' ||
                                  sequences[1].last_num != '0' ||
                                  sequences[2].last_num != '0' ||
                                  sequences[3].last_num != '0'
                              "
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="12" sm="1" md="1">
                          <b-form-group
                            label="Longeur *"
                            label-for="sequence-facture-format-input"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="sequence-facture-format-input"
                              v-model="sequence.num_length"
                              type="number"
                              required
                              :disabled="
                                sequences[0].last_num != '0' ||
                                  sequences[1].last_num != '0' ||
                                  sequences[2].last_num != '0' ||
                                  sequences[3].last_num != '0'
                              "
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="12" sm="2" md="2">
                          <b-form-group
                            label="Partir de *"
                            label-for="sequence-facture-format-input"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="sequence-facture-format-input"
                              v-model="sequence.begin_from"
                              type="number"
                              required
                              :disabled="
                                sequences[0].last_num != '0' ||
                                  sequences[1].last_num != '0' ||
                                  sequences[2].last_num != '0' ||
                                  sequences[3].last_num != '0'
                              "
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="12" sm="2" md="2" class="mr-2">
                          <b-form-group
                            label="Date de début *"
                            label-for="swift-template-input"
                            class="input-modal-champ"
                          >
                            <date-picker
                              value-type="format"
                              type="date"
                              required
                              :value="sequence.begin_date"
                              v-model="sequence.begin_date"
                              input-class="custom-date-picker-filter"
                              class="custom-date-picker-calender-filter"
                              :disabled="
                                sequences[0].last_num != '0' ||
                                  sequences[1].last_num != '0' ||
                                  sequences[2].last_num != '0' ||
                                  sequences[3].last_num != '0'
                              "
                            ></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col col xs="12" sm="2" md="2">
                          <b-form-group
                            label="Date de fin *"
                            label-for="swift-template-input"
                            class="input-modal-champ"
                          >
                            <date-picker
                              value-type="format"
                              type="date"
                              required
                              :value="sequence.end_date"
                              v-model="sequence.end_date"
                              input-class="custom-date-picker-filter"
                              class="custom-date-picker-calender-filter"
                            ></date-picker>
                            <!-- :disabled="
                              sequences[0].last_num != '0' ||
                                sequences[1].last_num != '0' ||
                                sequences[2].last_num != '0' ||
                                sequences[3].last_num != '0'
                            " -->
                          </b-form-group>
                        </b-col>
                        <b-col col xs="12" sm="2" md="2">
                          <b-form-group
                            label="last num"
                            label-for="sequence-facture-format-input"
                            class="input-modal-champ"
                          >
                            <b-form-input
                              autocomplete="off"
                              id="sequence-facture-format-input"
                              v-model="sequence.last_num"
                              disabled
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <div col xs="12" sm="1" md="1" class="edit-action">
                          <div
                            class="action-icon"
                            @click="
                              BlockUnblockRibFilialeClick(
                                index,
                                filialeToUpdate
                              )
                            "
                            v-if="sequence.blocked == true"
                          >
                            <font-awesome-icon
                              icon="eye-slash"
                              class="fa-2x bell1"
                            />
                          </div>
                          <div
                            class="action-icon"
                            @click="
                              BlockUnblockRibFilialeClick(
                                index,
                                filialeToUpdate
                              )
                            "
                            v-if="
                              sequence.blocked == false &&
                                sequence.FoundRib != false
                            "
                          >
                            <font-awesome-icon icon="eye" class="fa-2x bell1" />
                          </div>
                        </div>
                      </b-row>
                    </b-row>
                  </div>
                </b-form-group>
                <!-- End séquences factures -->

                <div class="actionModel">
                  <div class="error-message">
                    <div v-if="getSequanceErrors" class="error">
                      <ul v-if="Array.isArray(getSequanceErrors)">
                        <li
                          v-for="(e, index) in getSequanceErrors"
                          :key="index"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ getSequanceErrors }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style mt-3">
                    <span>
                      Valider
                      <div
                        v-if="getLoadingSettingFilialeTh"
                        class="loading ml-2"
                      >
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </template>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- end Séquences factures filiale Modal -->

    <!-- Biens filiale Modal -->
    <b-modal
      ref="biensFilialeModal"
      id="biensFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Gestion des biens</div>
        <div class="iconClose" @click.prevent="hideBiensModal(filialeToUpdate)">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleAddBien(filialeToUpdate)"
            class="form-modal-custom-style"
          >
            <div v-if="getBienLoader" class="message text-aligne-center">
              <div
                class="loading-custom-template-block style-half-circle-loder"
              >
                <Half-circle-spinner
                  :animation-duration="1000"
                  :size="50"
                  :color="'#4d4bac'"
                  class="loeder"
                />
              </div>
            </div>
            <div v-else>
              <template>
                <!-- Begin séquences factures -->
                <b-form-group
                  label-for="filiale-biens-factures"
                  class="input-modal-champ mb-2"
                >
                  <div class="button-add-prop-filiale-th mb-2">
                    <b-button
                      variant="success"
                      class="bien-add-button ml-2"
                      @click="addLineBienFiliale()"
                    >
                      <font-awesome-icon icon="plus" /> Ajouter un bien
                    </b-button>
                  </div>
                  <div class="row" v-if="getBienOfiliale">
                    <b-row
                      class="row mb-2 rib_block"
                      v-for="(bien, index) in getBienOfiliale"
                      :key="index"
                    >
                      <div
                        class="action-icon cancel"
                        v-if="!bien.hasOwnProperty('id')"
                      >
                        <font-awesome-icon
                          icon="times"
                          class="fa-2x bell1 delete-prop-societe"
                          @click="DeleteLineBienFiliale(index)"
                        />
                      </div>
                      <!-- bien factures  -->
                      <b-col col xs="12" sm="3" md="3">
                        <b-form-group
                          label="Nature des locaux *"
                          label-for="bien-facture-format-input"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            autocomplete="off"
                            id="bien-facture-format-input"
                            v-model="bien.name"
                            required
                          >
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="12" sm="2" md="2">
                        <b-form-group
                          label="Locataire *"
                          label-for="bien-facture-format-input"
                          class="input-modal-champ"
                        >
                          <b-form-select
                            id="locataire-select"
                            v-model="bien.locataire_id"
                            :options="getListFilialesLocateur"
                            text-field="text"
                            value-field="value"
                            class="b-form-select-raduis"
                            required
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="12" sm="2" md="2">
                        <b-form-group
                          label="Intermédiaire *"
                          label-for="bien-facture-format-input"
                          class="input-modal-champ"
                        >
                          <b-form-select
                            id="locataire-select"
                            v-model="bien.intermediaire_id"
                            :options="getListFilialesIntermidier"
                            text-field="text"
                            value-field="value"
                            class="b-form-select-raduis"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="12" sm="2" md="2">
                        <div role="group" class="form-group input-modal-champ">
                          <label for="swift-template-input" class="d-block"
                            >TVA % *
                            <button
                              type="button"
                              v-b-modal.addtvaModal
                              class="ml-2 btn-add-tva-unite"
                            >
                              <font-awesome-icon
                                icon="plus"
                                class="icon-plus-tva-unite"
                              /></button
                          ></label>

                          <b-form-select
                            id="tva"
                            v-model="bien.tva"
                            required
                            :options="getAllTvas"
                            class="b-form-select-raduis"
                            text-field="valeur"
                            value-field="valeur"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="TVA %"
                          ></b-form-select>
                        </div>
                      </b-col>
                      <b-col
                        col
                        xs="12"
                        sm="2"
                        md="2"
                        v-if="bien.intermediaire_id != null"
                      >
                        <b-form-group
                          label="TVA Intermediaire % *"
                          label-for="swift-template-input"
                          class="input-modal-champ"
                        >
                          <b-form-select
                            id="tva"
                            v-model="bien.tva_intermediaire"
                            required
                            :options="getAllTvas"
                            class="b-form-select-raduis"
                            text-field="valeur"
                            value-field="valeur"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="TVA %"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <div
                        col
                        xs="12"
                        sm="1"
                        md="1"
                        class="edit-action"
                        v-if="bien.hasOwnProperty('id')"
                      >
                        <div
                          class="action-icon"
                          @click="
                            BlockUnblockBienFilialeClick(index, filialeToUpdate)
                          "
                          v-if="bien.blocked == true"
                        >
                          <font-awesome-icon
                            icon="eye-slash"
                            class="fa-2x bell1"
                          />
                        </div>
                        <div
                          class="action-icon"
                          @click="
                            BlockUnblockBienFilialeClick(index, filialeToUpdate)
                          "
                          v-if="bien.blocked == false"
                        >
                          <font-awesome-icon icon="eye" class="fa-2x bell1" />
                        </div>
                      </div>

                      <b-col col xs="12" sm="6" md="6">
                        <b-form-group
                          label="Adresse *"
                          label-for="bien-facture-format-input"
                          class="input-modal-champ"
                        >
                          <b-form-input
                            autocomplete="off"
                            id="bien-facture-format-input"
                            v-model="bien.address"
                            type="text"
                            required
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col col xs="12" sm="6" md="6">
                        <b-form-group
                          label="Description"
                          label-for="bien-facture-format-input"
                          class="input-modal-champ"
                        >
                          <b-form-textarea
                            rows="1"
                            cols="20"
                            class="textarea-bien"
                            id="description_bien"
                            v-model="bien.description"
                            autocomplete="off"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
                <!-- End séquences factures -->

                <div class="actionModel" v-if="getBienOfiliale.length">
                  <div class="messageError">
                    <div v-if="getBienErrors" class="error">
                      <ul v-if="Array.isArray(getBienErrors)">
                        <li v-for="(e, index) in getBienErrors" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ getBienErrors }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style mt-3">
                    <span>
                      Valider
                      <div
                        v-if="getLoadingSettingFilialeTh"
                        class="loading ml-2"
                      >
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </template>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- end Biens filiale Modal -->

    <!-- Delete Bien Modal -->
    <b-modal
      ref="deleteBienModal"
      id="deleteBienModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un Bien</div>
        <div
          class="iconClose"
          @click.prevent="hideDeleteBienModal('deleteBienModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerBien"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>Êtes-vous sur de vouloir supprimer ce bien ?</p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="loadingDeleteBien" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- end Delete Bien Modal -->

    <!-- Delete Sequence Modal -->
    <b-modal
      ref="deleteSequenceModal"
      id="deleteSequenceModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une séquance</div>
        <div
          class="iconClose"
          @click.prevent="hideDeleteBienModal('deleteSequenceModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerSequence"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>Êtes-vous sur de vouloir supprimer cette séquence ?</p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="loadingDeleteSequence" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>

    <!-- end Delete Sequence Modal -->
    <b-modal
      :hide-footer="true"
      no-close-on-backdrop
      id="MasterFilialList"
      ref="MasterFilialList"
      title="Liste des sociétes"
    >
      <div v-if="loadingSociteOfFilial" class="message text-aligne-center">
        <div class="loading-custom-template-block style-half-circle-loder">
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#4d4bac'"
            class="loeder"
          />
        </div>
      </div>
      <b-table-simple v-else class="table-fiche m-0 p-0" responsive>
        <b-tbody>
          <b-tr class="title-ligne hearder-sticky">
            <b-th class="newcolor">Societé</b-th>
            <b-th class="newcolor">Date début</b-th>
            <b-th class="newcolor">Date fin</b-th>
          </b-tr>
          <template v-for="item in getSocieteOfMasterFilial">
            <b-tr :key="item.id">
              <b-td>{{ item.name }}</b-td>
              <b-td>{{ item.date_debut }}</b-td>
              <b-td>{{ item.date_fin }}</b-td>
            </b-tr>
          </template>
          <b-tr
            v-if="
              getSocieteOfMasterFilial && getSocieteOfMasterFilial.length == 0
            "
          >
            <b-td colspan="3"> Il n'y a aucun enregistrement à afficher</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="actionModel d-flex justify-content-center mt-2">
        <b-button class="button-valide-style" @click="hideModalMasterFiliales">
          Fermer
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="addtvaModal"
      id="addtvaModal"
      no-close-on-backdrop
      :hide-footer="true"
      @hidden="resteTvaUniteModal"
      title="Ajouter tva"
      modal-class="cutsom-modal-bootstrap"
      size="sm"
    >
      <b-form-group label="Tva" label-for="unite" class="input-modal-champ">
        <b-form-input
          id="tva"
          v-model="valeur"
          required
          type="number"
          autocomplete="off"
        ></b-form-input>
      </b-form-group>
      <div class="error-message d-flex justify-content-center">
        <div class="error">
          <div>{{ errorTvaUnite }}</div>
        </div>
      </div>
      <div class="form-modal-custom-style mt-2">
        <div class="actionModel">
          <b-button class="button-valide-style" @click="addTav('tva')">
            <span>
              Valider
              <div v-if="loadingTvaUnite" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Restaurer Filiale Modal -->

    <!-- Restaurer User Modal -->
    <b-modal
      ref="steRestaurerModal"
      id="steRestaurerModal"
      no-close-on-backdrop
      title="Restaurer une société "
      :hide-footer="true"
    >
      <p>
        Êtes-vous sûr de vouloir restaurer cet société
        <strong class="name">
          {{ steToRestaurer ? steToRestaurer.name : '' }}
        </strong>
        ?
      </p>
      <div class="form-modal-custom-style">
        <div class="messageError">
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button v class="button-valide-style" @click="handleRestaurer">
            <span>
              Confirmer
              <div v-if="isLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div></div></span
          ></b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Restaurer Fililale Modal -->
    <!-- Ribs Modal -->
    <b-modal
      ref="ribsFlilaleModal"
      id="ribsFlilaleModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModalRib()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Gestion des ribs</div>
        <div class="iconClose" @click.prevent="hideRibModal(filialeToUpdate)">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <div v-if="getRibLoader" class="message text-aligne-center">
            <div class="loading-custom-template-block style-half-circle-loder">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>

          <template v-else>
            <div class="button-add-prop-filiale-th">
              <b-button
                variant="success"
                class="bien-add-button ml-2"
                @click="addLinetRibFiliale(filialeToUpdate)"
              >
                <font-awesome-icon icon="plus" /> Ajouter un RIB
              </b-button>
              <p class="mt-1" v-if="displayModification" style="color: green">
                changement effecter
              </p>
            </div>
            <b-form-group
              label=""
              label-for="filiale-swift"
              class="input-modal-champ mb-0 mt-2"
            >
              <b-row
                class="mb-1 rib_block"
                v-for="(rib, index) in getRibOfFilials"
                :key="index"
              >
                <!-- SWIFT && IBAN  -->
                <b-col col xs="12" sm="5" md="5">
                  <b-form-group
                    label="Iban *"
                    label-for="iban-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="iban-template-input"
                      v-model="rib.iban"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="12" sm="5" md="5">
                  <b-form-group
                    label="Swift *"
                    label-for="swift-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="rib.swift"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <div class="edit-action">
                  <div
                    class="action-icon validate"
                    @click="handleSubmitRibFiliale(index, rib)"
                    v-if="!rib.hasOwnProperty('id')"
                  >
                    <font-awesome-icon
                      title="Ajouter Rib"
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>

                  <div
                    class="action-icon validate"
                    @click="handleUpdateRibFiliale(index, rib)"
                    v-if="rib.hasOwnProperty('id') && rib.blocked === false"
                  >
                    <font-awesome-icon
                      title="Modifier rib"
                      icon="check"
                      class="fa-2x bell1"
                    />
                  </div>

                  <div
                    class="action-icon"
                    @click="BlockUnblockRibFilialeClick(index, rib)"
                    v-if="rib.blocked == true && rib.hasOwnProperty('id')"
                  >
                    <font-awesome-icon
                      title="Déblocker rib"
                      icon="eye-slash"
                      class="fa-2x bell1 m-2"
                    />
                  </div>
                  <div
                    class="action-icon"
                    @click="BlockUnblockRibFilialeClick(index, rib)"
                    v-if="rib.blocked == false && rib.hasOwnProperty('id')"
                  >
                    <font-awesome-icon
                      title="blocker rib"
                      icon="eye"
                      class="fa-2x bell1 m-2"
                    />
                  </div>

                  <div
                    class="action-icon cancel"
                    @click="DeleteRibFiliale(index, rib)"
                    v-if="!rib.hasOwnProperty('id')"
                  >
                    <font-awesome-icon icon="times" class="fa-2x bell1 m-2" />
                  </div>
                  <div v-if="rib.blocked == false">
                    <div
                      v-if="rib.favori === true"
                      class="action-icon cancel"
                      @click="handleUpdateRibFilialeFavorit(index, rib, true)"
                    >
                      <font-awesome-icon
                        icon="heart"
                        title="rib favorie utiliser par défaut dans la generation des factures en ligne"
                        class="fa-2x bell1 m-2"
                      />
                    </div>
                  </div>
                  <div v-if="rib.blocked == false">
                    <div
                      v-if="rib.favori === false"
                      class="action-icon cancel"
                      @click="handleUpdateRibFilialeFavorit(index, rib, false)"
                    >
                      <font-awesome-icon
                        title="rib favorie utiliser par défaut dans la generation des factures en ligne"
                        icon="heart"
                        class="fa-2x hart-not-favorit bell1 m-2"
                      />
                    </div>
                  </div>
                </div>
              </b-row>
            </b-form-group>
          </template>
          <div v-if="ribErrors" class="actionModel">
            <div class="messageError">
              <div class="error">
                <div class="color-message">{{ ribErrors }}</div>
              </div>
            </div>
          </div>
          <div v-else class="actionModel">
            <div class="messageError">
              <div v-if="getErrors" class="error">
                <ul v-if="Array.isArray(getErrors)">
                  <li
                    class="color-message"
                    style="list-style: none"
                    v-for="(e, index) in getErrors"
                    :key="index"
                  >
                    {{ e }}
                  </li>
                </ul>
                <div v-else class="color-message">{{ getErrors }}</div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </b-modal>
    <!-- END Ribs Modal-->

    <!--Modal Gestion Extra Mails-->
    <b-modal
      ref="EmailFilialeModal"
      id="EmailFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Gestion des Extra Mails</div>
        <div
          class="iconClose"
          @click.prevent.stop="hideModalAddEmail('EmailFilialeModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <div v-if="mailsIsLoading" class="message text-aligne-center">
            <div class="loading-custom-template-block style-half-circle-loder">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>
          <template v-else>
            <div class="button-add-prop-filiale-th">
              <b-button
                variant="success"
                class="bien-add-button ml-2"
                @click="addEmail()"
              >
                <font-awesome-icon icon="plus" /> Ajouter une Adresse Email
              </b-button>
            </div>
            <b-form-group
              label="Adresse Email"
              label-for="filiale-swift"
              class="input-modal-champ mb-0"
            >
              <b-row
                class="mb-1 rib_block"
                v-for="(e, index) in mails"
                :key="index"
              >
                <b-col class="col-10">
                  <b-form-group
                    label="Adresse Email *"
                    label-for="email-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'email' + index"
                      v-model="e.email"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <div class="edit-action mr-2">
                  <div
                    class="action-icon validate"
                    @click="updateMails(e, 'email' + index)"
                  >
                    <font-awesome-icon
                      v-if="indexCheck == 'email' + index"
                      title="Valider la modification "
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div
                    class="action-icon validate"
                    @click="changeDisabled('email' + index)"
                  >
                    <font-awesome-icon
                      v-if="show == false"
                      title="Modifier une Adresse Email"
                      icon="pen"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div>
                    <div class="action-icon cancel" @click="deleteEmail(e)">
                      <font-awesome-icon
                        icon="trash"
                        title="Supprimer une Adresse Email"
                        class="fa-2x bell1 m-2"
                      />
                    </div>
                  </div>
                </div>

                <div class="messageError">
                  <div v-if="updateMailError && id_add == index" class="error">
                    <ul v-if="Array.isArray(updateMailError)">
                      <li
                        v-for="(e, index) in updateMailError"
                        :key="index"
                        class="message-error"
                        style="color: red; list-style: none"
                      >
                        {{ e }}
                      </li>
                    </ul>
                    <div
                      v-else
                      class="message-error"
                      style="color: red; list-style: none"
                    >
                      {{ updateMailError }}
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row
                class="mb-1 rib_block"
                v-for="(e, i) in EmailList"
                :key="'email' + i"
              >
                <b-col class="col-10">
                  <b-form-group
                    label="Adresse Email *"
                    label-for="iban-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'email' + i"
                      v-model="e.email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <div class="edit-action mr-2">
                  <div
                    class="action-icon validate"
                    @click="addEmails(e.email, i)"
                  >
                    <font-awesome-icon
                      title="Ajouter une Adresse Email"
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div>
                    <div class="action-icon cancel" @click="deleteBlock(i)">
                      <font-awesome-icon
                        icon="trash"
                        title="Supprimer cette block"
                        class="fa-2x bell1 m-2"
                      />
                    </div>
                  </div>
                </div>
                <div class="messageError">
                  <div v-if="addMailsError && id_add == i" class="error">
                    <ul v-if="Array.isArray(addMailsError)">
                      <li
                        v-for="(e, index) in addMailsError"
                        :key="index"
                        class="message-error"
                        style="color: red; list-style: none"
                      >
                        {{ e }}
                      </li>
                    </ul>
                    <div
                      v-else
                      class="message-error"
                      style="color: red; list-style: none"
                    >
                      {{ addMailsError }}
                    </div>
                  </div>
                </div>
              </b-row>
            </b-form-group>
          </template>
          <div class="actionModel">
            <b-button
              type="submit"
              class="button-valide-style mt-1"
              @click.prevent.stop="hideModalAddEmail('EmailFilialeModal')"
            >
              <span> Fermer </span>
            </b-button>
          </div>
        </template>
      </Card>
    </b-modal>
    <!-- Modal Gestion Extra Mails -->

    <!-- MODAL delete Extra Mail -->
    <b-modal
      ref="deleteExtraMail"
      id="deleteExtraMail"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une Adresse Email</div>
        <div class="iconClose" @click="hideDelExtraMail()">
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form class="form-modal-custom-style">
        <div class="mt-4 ml-3">
          <p>Êtes-vous sur de vouloir supprimer cette Adresse Email?</p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="errorRemoveMail" class="error">
              <ul v-if="Array.isArray(errorRemoveMail)">
                <li
                  v-for="(e, index) in errorRemoveMail"
                  :key="'error' + index"
                >
                  {{ e }}
                </li>
              </ul>
              <div v-else class="message-error">{{ errorRemoveMail }}</div>
            </div>
          </div>
          <b-button
            type="submit"
            class="button-valide-style mt-1"
            @click.prevent.stop="DelExtraMails()"
          >
            <span>
              Valider
              <div v-if="loadingRemoveMail" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!--End Modal Delete Extra Mail-->

    <!-- Modal Gestion Pseudo -->
    <b-modal
      ref="PseudoFilialeModal"
      id="PseudoFilialeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Gestion des pseudos</div>
        <div
          class="iconClose"
          @click.prevent.stop="hidePseudoModal('PseudoFilialeModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <div v-if="pseudo_loader" class="message text-aligne-center">
            <div class="loading-custom-template-block style-half-circle-loder">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>
          <template v-else>
            <div class="button-add-prop-filiale-th">
              <b-button
                variant="success"
                class="bien-add-button ml-2"
                @click="addPseudo()"
              >
                <font-awesome-icon icon="plus" /> Ajouter un pseudo
              </b-button>
            </div>
            <b-form-group
              label="Pseudo"
              label-for="filiale-swift"
              class="input-modal-champ mb-0"
            >
              <b-row
                class="mb-1 rib_block"
                v-for="(p, index) in pseudos"
                :key="index"
              >
                <b-col class="col-10">
                  <b-form-group
                    label="Pseudo *"
                    label-for="pseudo-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'pseudo' + index"
                      v-model="p.pseudo"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <div class="edit-action mr-2">
                  <div
                    class="action-icon validate"
                    @click="updatePseudos(p, 'pseudo' + index)"
                  >
                    <font-awesome-icon
                      v-if="indexPseudoCheck == 'pseudo' + index"
                      title="Valider la modification "
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div
                    class="action-icon validate"
                    @click="changeDisabledPseudo('pseudo' + index, index)"
                  >
                    <font-awesome-icon
                      v-if="showPseudo == false && p.pseudo_alpha == false"
                      title="Modifier un pseudo"
                      icon="pen"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div>
                    <div class="action-icon cancel" @click="deletePseudos(p)">
                      <font-awesome-icon
                        v-if="p.pseudo_alpha == false"
                        icon="trash"
                        title="Supprimer un pseudo"
                        class="fa-2x bell1 m-2"
                      />
                    </div>
                  </div>
                </div>
                <div class="messageError">
                  <div
                    v-if="errorUpdatePseudo && id_addPseudo == index"
                    class="error"
                  >
                    <ul v-if="Array.isArray(errorUpdatePseudo)">
                      <li
                        v-for="(e, index) in errorUpdatePseudo"
                        :key="index"
                        class="message-error"
                        style="color: red; list-style: none"
                      >
                        {{ e }}
                      </li>
                    </ul>
                    <div
                      v-else
                      class="message-error"
                      style="color: red; list-style: none"
                    >
                      {{ errorUpdatePseudo }}
                    </div>
                  </div>
                </div>
              </b-row>
              <b-row
                class="mb-1 rib_block"
                v-for="(p, i) in pseudoList"
                :key="'pseudo' + i"
              >
                <b-col class="col-10">
                  <b-form-group
                    label="Pseudo *"
                    label-for="iban-template-input"
                    class="input-modal-champ"
                  >
                    <b-form-input
                      autocomplete="off"
                      :id="'pseudo' + i"
                      v-model="p.pseudo"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <div class="edit-action mr-2">
                  <div
                    class="action-icon validate"
                    @click="addPseudos(p.pseudo, i)"
                  >
                    <font-awesome-icon
                      title="Ajouter un pseudo"
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>
                  <div>
                    <div
                      class="action-icon cancel"
                      @click="deleteBlockPseudo(i)"
                    >
                      <font-awesome-icon
                        icon="trash"
                        title="Supprimer cette block"
                        class="fa-2x bell1 m-2"
                      />
                    </div>
                  </div>
                </div>
                <div class="messageError">
                  <div v-if="errorAddPseudo && id_addPseudo == i" class="error">
                    <ul v-if="Array.isArray(errorAddPseudo)">
                      <li
                        v-for="(e, index) in errorAddPseudo"
                        :key="index"
                        class="message-error"
                        style="color: red; list-style: none"
                      >
                        {{ e }}
                      </li>
                    </ul>
                    <div
                      v-else
                      class="message-error"
                      style="color: red; list-style: none"
                    >
                      {{ errorAddPseudo }}
                    </div>
                  </div>
                </div>
              </b-row>
            </b-form-group>
          </template>
          <div class="actionModel">
            <b-button
              type="submit"
              class="button-valide-style mt-1"
              @click.prevent.stop="hidePseudoModal('PseudoFilialeModal')"
            >
              <span> Fermer </span>
            </b-button>
          </div>
        </template>
      </Card>
    </b-modal>
    <!--End Modal Gestion Pseudo -->

    <!-- Modal Delete Pseudo -->
    <b-modal
      ref="deletePseudo"
      id="deletePseudo"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un pseudo</div>
        <div
          class="iconClose"
          @click.prevent.stop="hideModalDeletePseudo('deletePseudo')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form class="form-modal-custom-style">
        <div class="mt-4 ml-3">
          <p>Êtes-vous sur de vouloir supprimer ce pseudo?</p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="errorRemovePseudo" class="error">
              <ul v-if="Array.isArray(errorRemovePseudo)">
                <li
                  v-for="(e, index) in errorRemovePseudo"
                  :key="'error' + index"
                >
                  {{ e }}
                </li>
              </ul>
              <div v-else class="message-error">{{ errorRemovePseudo }}</div>
            </div>
          </div>
          <b-button
            type="submit"
            class="button-valide-style mt-1"
            @click.prevent.stop="DelPseudo()"
          >
            <span>
              Valider
              <div v-if="pseudo_remove_loader" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>

    <!--End Modal Gestion Pseudo -->

    <!-- Societe facture Modal -->
    <b-modal
      ref="societeFactureFlilaleModal"
      id="societeFactureFlilaleModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModalSocieteFacture()"
      no-close-on-backdrop
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-filiale-th"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Gestion société factures</div>
        <div
          class="iconClose"
          @click.prevent="hideSocieteFactureModal(filialeToUpdate)"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          {{ getSocieteFactureLoader }}
          <div
            v-if="getSocieteFactureLoader == true"
            class="message text-aligne-center"
          >
            <div class="loading-custom-template-block style-half-circle-loder">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>

          <template v-else>
            <div class="button-add-prop-filiale-th">
              <b-button
                variant="success"
                class="bien-add-button ml-2"
                @click="addLinetSocieteFactureFiliale(filialeToUpdate)"
              >
                <font-awesome-icon icon="plus" /> Ajouter une société à facturer
              </b-button>
            </div>
            <b-form-group
              label=""
              label-for="filiale-swift"
              class="input-modal-champ mb-0 mt-2"
            >
              <b-row
                class="mb-1 rib_block"
                v-for="(societeFacture, index) in getSocieteFacturesOfFilile"
                :key="index"
              >
                <b-col col xs="12" sm="8" md="8">
                  <b-form-group
                    label="Société *"
                    label-for="bien-facture-format-input"
                    class="input-modal-champ"
                  >
                    <b-form-select
                      id="locataire-select"
                      v-model="societeFacture.societe_id"
                      :options="getListFilialesLocateur"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis"
                      required
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <div class="edit-action">
                  <div
                    class="action-icon validate"
                    v-if="societeFacture.societe_id"
                    @click="
                      handleSubmitFilialeSocieteFacture(index, societeFacture)
                    "
                  >
                    <font-awesome-icon
                      title="Ajouter société"
                      icon="check"
                      class="fa-2x bell1 m-1"
                    />
                  </div>

                  <div
                    v-if="societeFacture.id"
                    class="action-icon cancel"
                    @click="DeleteFilialeSocieteFacture(index, societeFacture)"
                  >
                    <font-awesome-icon icon="times" class="fa-2x bell1 m-2" />
                  </div>
                  <div
                    v-if="societeFacture.favori === true && societeFacture.id"
                    class="action-icon cancel"
                    @click="
                      handleUpdateFilialeSocieteFactureFavorit(
                        index,
                        societeFacture,
                        true
                      )
                    "
                  >
                    <font-awesome-icon
                      icon="heart"
                      title="rib favorie utiliser par défaut dans la generation des factures en ligne"
                      class="fa-2x bell1 m-2"
                    />
                  </div>
                  <div
                    v-if="societeFacture.favori === false && societeFacture.id"
                    class="action-icon cancel"
                    @click="
                      handleUpdateFilialeSocieteFactureFavorit(
                        index,
                        societeFacture,
                        false
                      )
                    "
                  >
                    <font-awesome-icon
                      title="rib favorie utiliser par défaut dans la generation des factures en ligne"
                      icon="heart"
                      class="fa-2x hart-not-favorit bell1 m-2"
                    />
                  </div>
                </div>
              </b-row>
            </b-form-group>
          </template>
          <div class="actionModel">
            <div class="messageError">
              <div v-if="getErrors" class="error">
                <ul v-if="Array.isArray(getErrors)">
                  <li v-for="(e, index) in getErrors" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else class="color-message">{{ getErrors }}</div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </b-modal>
    <!-- END Societe facture Modal-->
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import InputColorPicker from 'vue-native-color-picker';
export default {
  name: 'Gestion-des-sociétés',
  data() {
    return {
      showPseudo: false,
      indexPseudoCheck: null,
      pseudoToDelete: null,
      id_pseudo: null,
      pseudoList: [],
      indexCheck: null,
      id_add: null,
      i: null,
      show: false,
      id: null,
      EmailList: [],
      showModal: false,
      searchValue: null,
      typeFilter: null,
      typeSte: null,
      sequences_factures: [],
      uniqueArrayOfCountries: [],
      masterFilialesToDisplay: null,
      loadingSociteOfFilial: false,
      steToRestaurer: null,
      deleted: 'no',
      isLoading: false,
      displayModification: false,
      options: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Organisme', value: 'organisme' },
        { text: 'SCI', value: 'sci' },
        { text: 'Master Filiale', value: 'master filiale' }
      ],
      optionsTypeForFitre: [
        { text: 'Tous', value: null },
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Organisme', value: 'organisme' },
        { text: 'SCI', value: 'sci' },
        { text: 'Master Filiale', value: 'master filiale' }
      ],
      optionTypeSte: [
        { text: 'Tous', value: null },
        { text: 'EURL', value: 'EURL' },
        { text: 'SASU', value: 'SASU' },
        { text: 'SARL', value: 'SARL' },
        { text: 'SA', value: 'SA' },
        { text: 'SAS', value: 'SAS' },
        { text: 'SCP', value: 'SCP' },
        { text: 'SCS', value: 'SCS' },
        { text: 'SCA', value: 'SCA' },
        { text: 'SEL', value: 'SEL' }
      ],
      typeSteFilter: [
        'EURL',
        'SASU',
        'SARL',
        'SA',
        'SAS',
        'SCP',
        'SNC',
        'SCS',
        'SCA',
        'SEL'
      ],
      genreList: ['M.', 'Mme', 'Mmes', 'Mrs', 'M & Mme'],
      ribErrors: null,
      filialeToAdd: {
        couleur: null,
        type: 'professionnel',
        name: null,
        depots: [],
        type_societe: null,
        volant: false,
        abreviation: null,
        responsable_depot: null,
        image: null,
        imageData: '',
        dejaVolant: false,
        siret: null,
        adresse: null,
        responsables: [],
        prenom: null,
        civilite: null,
        nom_famille: null,
        siren_value: null,
        tva_value: null,
        site_internet: null,
        ville: null,
        pays: null,
        iban: null,
        banque: null,
        swift: null,
        fax: null,
        telephone: null,
        description: null,
        telephone_portable: null,
        masterFiliales: [],
        Files: [],
        ribs: [],
        is_societe_support: false
      },
      error: null,
      loading: false,
      filialeToDelete: null,
      filialeToUpdate: null,
      DepotToUpdate: [],
      Files: [],
      fileToDelete: null,
      fileToRename: {
        name: null
      },
      indexFileToDelete: null,
      inputFileToRename: null,
      sequencesPlaceholders: [
        { Abbréviation: '{{abbr}}' },
        { Mois: '{{month}}' },
        { Année: '{{year}}' }
      ],
      copierText: false,
      listSocieteSCI: [
        { value: 1, text: 'Societe 1' },
        { value: 2, text: 'Societe 2' },
        { value: 3, text: 'Societe 3' },
        { value: 4, text: 'Societe 4' }
      ],
      bienToDelete: null,
      indexBienToDelete: null,
      indexSequencesToDelete: null,
      loadingDeleteBien: false,
      loadingDeleteSequence: false,
      errorTvaUnite: null,
      valeur: null,
      loadingTvaUnite: false,
      listTva: [],
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      seqeunceLoding: false,
      emailToDelete: null,
      id_addPseudo: null
    };
  },
  methods: {
    ...mapActions([
      'resetPseudoFiliale',
      'addNewPseudo',
      'updatePseudo',
      'deletePseudo',
      'getAllPseudoFiliale',
      'resetExtraMailSociete',
      'updateExtraMails',
      'deleteExtraMails',
      'getEmailsById',
      'createExtraMails',
      'AddSettingFilialeTh',
      'getSettingFilialeTh',
      'deleteSettingFilialeTh',
      'fetchDepotsTh',
      'addDepotToFilialeAction',
      'removeDepotFromFilialeAction',
      'updateNomResponsableDepotAction',
      'fetchResponsableFilialeFreeTh',
      'getAllCountreies',
      'getAllTypeSocieteSupport',
      'addRibToFiliale',
      'updateRibFiliale',
      'blockunblockRibFiliale',
      'removeFilialeFile',
      'renameFilialeFile',
      'removeFilialeRib',
      'fetchSequenceFactureFields',
      'blockunblockBienFiliale',
      'removeFilialeBien',
      'removeFilialeSequence',
      'getAllMasterFilials',
      'getAllSociteOfMasterFilials',
      'getTvas',
      'addUniteTva',
      'restoreFiliale',
      'getAllRibOfFiliale',
      'addSequanceForSociete',
      'getAllSequancesOfFiliale',
      'getAllBienOfFiliale',
      'addBienForSociete',
      'resteModalOfRibs',
      'getAllSocieteFactureOfFiliale',
      'resteModalOfSocieteFacture',
      'updateSocieteFactureFiliale',
      'addSocieteFactureToFiliale',
      'removeFilialeSocieteFacture',
      'getAllFilialesListe'
    ]),
    updatePseudos(p, index) {
      let response = this.updatePseudo(p).then(result => {
        if (result == true) {
          const elem = document.getElementById(index);
          elem.setAttribute('disabled', '');
          this.showPseudo = false;
          this.indexPseudoCheck = null;
        }
      });
    },
    changeDisabledPseudo(index, i) {
      this.id_addPseudo = i;
      this.indexPseudoCheck = index;
      this.showPseudo = true;
      const elem = document.getElementById(index);
      elem.removeAttribute('disabled');
    },
    DelPseudo() {
      this.deletePseudo(this.pseudoToDelete);
      this.$refs['deletePseudo'].hide();
    },
    deletePseudos(p) {
      this.pseudoToDelete = p.id;
      this.$refs['deletePseudo'].show();
    },
    hideModalDeletePseudo(ref) {
      this.$refs[ref].hide();
    },
    deleteBlockPseudo(i) {
      this.pseudoList.splice(i, 1);
      this.resetPseudoFiliale(null);
    },
    addPseudos(p, index) {
      this.id_addPseudo = index;
      let formData = new FormData();
      formData.append('filiale_id', this.id_pseudo);
      formData.append('pseudo', p);
      this.addNewPseudo(formData).then(result => {
        if (result == true) {
          this.deleteBlockPseudo(index);
        }
      });
    },
    hidePseudoModal(ref) {
      this.$refs[ref].hide();
    },
    addPseudo() {
      this.pseudoList.push({
        pseudo: ''
      });
    },
    addPseudoFiliale(item) {
      this.id_pseudo = item.id;
      this.getAllPseudoFiliale(this.id_pseudo);
      this.$refs['PseudoFilialeModal'].show();
    },
    updateMails(e, index) {
      let response = this.updateExtraMails(e).then(result => {
        if (result == true) {
          const elem = document.getElementById(index);
          elem.setAttribute('disabled', '');
          this.show = false;
          this.indexCheck = null;
        }
      });
    },
    async changePerPage() {
      this.page = 1;
      await this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
    },

    async handleAddBien(filiale) {
      let objectbien = {
        id: filiale.id,
        biens: this.getBienOfiliale
      };
      const response = await this.addBienForSociete(objectbien);
      if (response) {
        this.$refs['biensFilialeModal'].hide();
      }
    },
    DelExtraMails() {
      this.deleteExtraMails(this.emailToDelete);
      this.$refs['deleteExtraMail'].hide();
    },
    hideDelExtraMail() {
      this.$refs['deleteExtraMail'].hide();
    },
    changeDisabled(index) {
      this.indexCheck = index;
      this.show = true;
      const elem = document.getElementById(index);
      elem.removeAttribute('disabled');
    },
    hideModalAddEmail(ref) {
      this.$refs[ref].hide();
      this.EmailList = [];
    },
    deleteEmail(e) {
      this.emailToDelete = e.id;
      this.$refs['deleteExtraMail'].show();
    },
    addEmails(e, i) {
      this.id_add = i;
      let formData = new FormData();
      formData.append('filiale_id', this.id);
      formData.append('email', e);
      this.createExtraMails(formData).then(result => {
        if (result == true) {
          this.deleteBlock(i);
        }
      });
    },
    deleteBlock(i) {
      this.EmailList.splice(i, 1);
      this.resetExtraMailSociete(null);
    },
    addEmail() {
      this.EmailList.push({
        email: null
      });
    },
    adresseMail(item) {
      this.id = item.id;
      this.getEmailsById(this.id);
      this.$refs['EmailFilialeModal'].show();
    },
    async handleClickRestaurer(ste) {
      this.steToRestaurer = ste;
      this.$refs['steRestaurerModal'].show();
    },
    async handleRestaurer() {
      this.isLoading = true;
      const response = await this.restoreFiliale(this.steToRestaurer.id);
      if (response) {
        this.isLoading = false;
        this.hideModal('steRestaurerModal');
      } else {
        this.isLoading = false;
      }
    },
    handlClickActive(data) {
      this.deleted = data;
      this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
    },

    async handleAddSequance(filiale) {
      let objectSeq = {
        id: filiale.id,
        sequences_factures: this.getSequancesofFilile
      };
      const response = await this.addSequanceForSociete(objectSeq);
      if (response) {
        this.$refs['sequencesFacturesFilialeModal'].hide();
      }
    },
    async openMasterFlilialsModal(item) {
      this.loadingSociteOfFilial = true;
      this.$refs['MasterFilialList'].show();
      await this.getAllSociteOfMasterFilials(item);
      this.loadingSociteOfFilial = false;
    },
    hideModalMasterFiliales() {
      this.$refs['MasterFilialList'].hide();
    },
    disabledStartDate(date, index) {
      return (
        this.filialeToAdd?.masterFiliales[index + 1]?.date_fin &&
        date < new Date(this.filialeToAdd?.masterFiliales[index + 1]?.date_fin)
      );
    },
    disabledEndDate(date, index) {
      return (
        this.filialeToAdd?.masterFiliales[index]?.date_debut &&
        date < new Date(this.filialeToAdd?.masterFiliales[index]?.date_debut)
      );
    },
    disabledStartDateUpdate(date, index) {
      return (
        this.filialeToUpdate?.masterFiliales[index + 1]?.date_fin &&
        date <
          new Date(this.filialeToUpdate?.masterFiliales[index + 1]?.date_fin)
      );
    },
    disabledEndDateUpdate(date, index) {
      return (
        this.filialeToUpdate?.masterFiliales[index]?.date_debut &&
        date < new Date(this.filialeToUpdate?.masterFiliales[index]?.date_debut)
      );
    },
    addNewMasterFilial(type) {
      if (type === 'add') {
        this.filialeToAdd?.masterFiliales?.unshift({
          master_id: null,
          date_debut: null,
          date_fin: null
        });
      }
      if (type === 'update') {
        this.filialeToUpdate?.masterFiliales?.unshift({
          master_id: null,
          date_debut: null,
          date_fin: null
        });
      }
    },
    deleteMasterFilial(index, type) {
      if (type === 'add') {
        this.filialeToAdd.masterFiliales.splice(index, 1);
      } else {
        this.filialeToUpdate.masterFiliales.splice(index, 1);
      }
    },
    resetModalRib() {
      this.resteModalOfRibs();
      this.ribErrors = null;
    },
    resetModalSocieteFacture() {
      this.resteModalOfSocieteFacture();
    },
    resetModal() {
      this.filialeToAdd = {
        couleur: null,
        type: 'professionnel',
        type_societe: null,
        name: null,
        depots: [],
        abreviation: null,
        volant: false,
        responsable_depot: null,
        image: null,
        imageData: '',
        siret: null,
        adresse: null,
        responsables: [],
        prenom: null,
        civilite: null,
        nom_famille: null,
        siren_value: null,
        tva_value: null,
        site_internet: null,
        ville: null,
        pays: null,
        iban: null,
        banque: null,
        swift: null,
        fax: null,
        telephone: null,
        description: null,
        telephone_portable: null,
        masterFiliales: [],
        Files: [],
        ribs: [],
        extraMails: []
      };
      this.error = null;
      this.loading = false;
      this.filialeToDelete = null;
      this.filialeToUpdate = null;
      this.DepotToUpdate = [];
      this.dejaVolant = false;
      this.fetchResponsableFilialeFreeTh();
      this.EmailList = [];
    },
    onChangeTypeForm(typeParam) {
      this.filialeToAdd = {
        couleur: null,
        type: typeParam,
        type_societe: null,
        name: null,
        depots: [],
        volant: false,
        responsable_depot: null,
        image: null,
        imageData: '',
        siret: null,
        adresse: null,
        responsables: null,
        prenom: null,
        civilite: null,
        nom_famille: null,
        siren_value: null,
        tva_value: null,
        site_internet: null,
        ville: null,
        pays: null,
        iban: null,
        banque: null,
        swift: null,
        fax: null,
        telephone: null,
        description: null,
        telephone_portable: null,
        masterFiliales: [],
        Files: [],
        ribs: [],
        extraMails: []
      };
      this.getSequancesofFilile = [];
      this.error = null;
      this.loading = false;
      this.filialeToDelete = null;
      this.filialeToUpdate = null;
      this.DepotToUpdate = [];
      this.dejaVolant = false;
      this.fetchResponsableFilialeFreeTh();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    hideDeleteFileModal(ref) {
      this.$refs[ref].hide();
    },
    hideDeleteBienModal(ref) {
      this.$refs[ref].hide();
    },
    async handleSubmitFiliale() {
      let ArrayOfStartDate = this.filialeToAdd.masterFiliales.filter(
        item => item.date_debut === null
      );
      if (ArrayOfStartDate.length) {
        this.error = 'Date début des masters filiales obligatoire';
        return;
      }
      this.loading = true;
      const response = await this.AddSettingFilialeTh(this.filialeToAdd);
      if (response.success) {
        if (this.filialeToAdd.type === 'master filiale') {
          this.getAllMasterFilials();
        }
        this.error = null;
        this.loading = false;
        this.onChangeType;
        this.hideModal('addFilialeModal');
        this.fetchDepotsTh('no');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async handleSuprimerFiliale() {
      const response = this.deleteSettingFilialeTh(this.filialeToDelete);
      if (response) {
        this.hideModal('deleteFilialeModal');
        await this.fetchDepotsTh('no');
      }
    },
    deleteFiliale(item) {
      this.filialeToDelete = item;
      this.$refs['deleteFilialeModal'].show();
    },
    async addDepotToFiliale(filiale) {
      this.dejaVolant = false;
      this.filialeToUpdate = structuredClone(filiale);
      if (filiale.volant && filiale.volant == true) {
        this.dejaVolant = true;
      }
      this.$refs['updateFilialeModal'].show();
    },
    async duplicateFiliale(filiale) {
      this.dejaVolant = false;
      this.filialeToUpdate = structuredClone(filiale);
      if (filiale.volant && filiale.volant == true) {
        this.dejaVolant = true;
      }
      this.$refs['duplicateFilialeModal'].show();
    },
    async handleSubmitUpdateFiliale() {
      let ArrayOfStartDate = this?.filialeToUpdate?.masterFiliales?.filter(
        item => item.date_debut === null
      );
      if (ArrayOfStartDate?.length) {
        this.error = 'Date début des masters filiales obligatoire';
        return;
      }
      this.loading = true;
      const response = await this.addDepotToFilialeAction({
        filiale: this.filialeToUpdate,
        depot: this.DepotToUpdate,
        dejaVolant: this.dejaVolant
      });
      if (response.success) {
        if (this.filialeToUpdate.type === 'master filiale') {
          this.getAllMasterFilials();
        }
        this.error = null;
        this.loading = false;
        this.hideModal('updateFilialeModal');
        this.hideModal('sequencesFacturesFilialeModal');
        this.hideModal('biensFilialeModal');
        await this.fetchDepotsTh('no');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async handleDuplicateFiliale() {
      this.loading = true;
      const response = await this.AddSettingFilialeTh(this.filialeToUpdate);
      if (response.success) {
        this.error = null;
        this.loading = false;
        this.onChangeType;
        this.hideModal('duplicateFilialeModal');
        this.fetchDepotsTh('no');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    async deleteFilialeDepot(filiale, depot) {
      const response = await this.removeDepotFromFilialeAction({
        filiale: filiale,
        depot: depot
      });
      if (response) {
        this.fetchDepotsTh('no');
      }
    },
    updateNomResponsableDepot(e, filiale, depot) {
      this.updateNomResponsableDepotAction({
        name: e.target.value,
        depot: depot,
        filiale: filiale
      });
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = () => {
          this.filialeToAdd.imageData = input.files[0];
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewImageInUpdate: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = () => {
          this.filialeToUpdate.imageData = input.files[0];
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    async onChangeType() {
      this.typeSte = null;
      await this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
      this.setLocalStorageParamsFilialeTh();
    },
    async changeSearchValue(value) {
      this.searchValue = value;
      await this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
      this.setLocalStorageParamsFilialeTh();
    },
    async onChangeTypeSte() {
      await this.getSettingFilialeTh({
        per_page: this.perPage,
        page: this.page,
        type: this.typeFilter,
        type_societe: this.typeSte,
        search: this.searchValue,
        deleted: this.deleted
      });
      this.setLocalStorageParamsFilialeTh();
    },
    previewImageUpdate: function(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = () => {
          this.filialeToUpdate.imageData = input.files[0];
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    setLocalStorageParamsFilialeTh() {
      localStorage.setItem(
        'Params-gestion-filiale-th',
        JSON.stringify({
          type: this.typeFilter,
          type_societe: this.typeSte,
          search: this.searchValue
        })
      );
    },
    addLinetRibFiliale(filiale) {
      this.getRibOfFilials.push({
        swift: null,
        iban: null,
        blocked: false
      });
    },
    addLinetSocieteFactureFiliale(filiale) {
      this.getSocieteFacturesOfFilile.push({
        filiale_id: filiale.id,
        societe_id: null,
        favori: false
      });
    },
    addLineSequenceFactureFiliale() {
      let index = this.getSequancesofFilile.length;
      let obj = [
        {
          seqeunce: null,
          begin_date: null,
          end_date: null,
          num_length: 4,
          begin_from: 0,
          last_num: 0,
          FoundRib: false,
          changeSave: false,
          newLine: true,
          title: 'Séquence facture',
          type: 'seq_facture',
          index: index
        },
        {
          seqeunce: null,
          begin_date: null,
          end_date: null,
          num_length: 4,
          begin_from: 0,
          last_num: 0,
          FoundRib: false,
          changeSave: false,
          newLine: true,
          title: 'Séquence avoir',
          type: 'seq_avoir',
          index: index
        },
        {
          seqeunce: null,
          begin_date: null,
          end_date: null,
          num_length: 4,
          begin_from: 0,
          last_num: 0,
          FoundRib: false,
          changeSave: false,
          newLine: true,
          title: 'Séquence Proforma',
          type: 'seq_proforma',
          index: index
        },
        {
          seqeunce: null,
          begin_date: null,
          end_date: null,
          num_length: 4,
          begin_from: 0,
          last_num: 0,
          FoundRib: false,
          changeSave: false,
          newLine: true,
          title: 'Séquence LCR',
          type: 'seq_lcr',
          index: index
        }
      ];
      this.getSequancesofFilile.push(obj);
    },
    addLineBienFiliale() {
      let index = this.getBienOfiliale.length;
      let obj = {
        name: null,
        description: null,
        address: null,
        blocked: 0,
        intermediaire_id: null,
        tva: null,
        tva_intermediaire: null,
        locataire_id: null,
        newLine: true,
        index: index
      };
      // if (!this.getBienOfiliale) this.getBienOfiliale = [];
      this.getBienOfiliale.push(obj);
    },
    async DeleteRibFiliale(index, rib) {
      // const response = await this.removeFilialeRib({
      //   id: rib.id
      // });
      // if (response == true) {
      this.getRibOfFilials.splice(index, 1);
      // }
    },
    async handleSubmitRibFiliale(index, rib) {
      if (rib.swift === '' || rib.swift === null) {
        this.ribErrors = 'iban et swift sont obligatoirs';
        return;
      }
      if (rib.iban === '' || rib.iban === null) {
        this.ribErrors = 'iban et swift sont obligatoirs';
        return;
      }
      const response = await this.addRibToFiliale({
        filiale_id: this.filialeToUpdate.id,
        swift: rib.swift,
        iban: rib.iban
      });
      if (response) {
        this.ribErrors = null;
        this.displayModification = true;
        setTimeout(() => {
          this.displayModification = false;
        }, 800);
      }
    },
    async handleSubmitFilialeSocieteFacture(index, societeFacture) {
      const response = await this.addSocieteFactureToFiliale({
        filiale_id: this.filialeToUpdate.id,
        societe_id: societeFacture.societe_id,
        favori: societeFacture.favori,
        societeFacture: societeFacture
      });
      // if (response) {
      //   this.getAllRibOfFiliale(this.filialeToUpdate.id);
      // }
    },
    async DeleteFilialeSocieteFacture(index, societeFacture) {
      const response = await this.removeFilialeSocieteFacture({
        id: societeFacture.id
      });
      if (response == true) {
        this.getSocieteFacturesOfFilile.splice(index, 1);
      }
    },
    // async ChangeIcon(index, filiale) {
    //   filiale.ribs[index].changeSave = true;
    //   filiale.ribs[index].newLine =
    //     typeof filiale.ribs[index].id !== 'undefined' ? false : true;
    // },
    async handleUpdateRibFilialeFavorit(index, rib, status) {
      const response = await this.updateRibFiliale({
        id: rib.id,
        swift: rib.swift,
        iban: rib.iban,
        favori: !status,
        blocked: rib.blocked
      });
      if (response) {
        this.displayModification = true;
        setTimeout(() => {
          this.displayModification = false;
        }, 800);
      }
    },
    async handleUpdateFilialeSocieteFactureFavorit(
      index,
      societeFacture,
      status
    ) {
      const response = await this.updateSocieteFactureFiliale({
        id: societeFacture.id,
        societe_id: societeFacture.societe_id,
        favori: !status
      });
    },
    async handleUpdateRibFiliale(index, rib) {
      if (rib.swift === '' || rib.swift === null) {
        this.ribErrors = 'iban et swift sont obligatoirs';
        return;
      }
      if (rib.iban === '' || rib.iban === null) {
        this.ribErrors = 'iban et swift sont obligatoirs';
        return;
      }
      const response = await this.updateRibFiliale({
        id: rib.id,
        swift: rib.swift,
        iban: rib.iban,
        favori: rib.favori,
        blocked: rib.blocked
      });
      if (response) {
        this.ribErrors = null;
        this.displayModification = true;
        setTimeout(() => {
          this.displayModification = false;
        }, 800);
      }
    },
    async BlockUnblockRibFilialeClick(index, rib) {
      const response = await this.updateRibFiliale({
        id: rib.id,
        swift: rib.swift,
        iban: rib.iban,
        favori: rib.favori,
        blocked: rib.blocked ? false : true
      });
      if (response == true) {
        this.displayModification = true;
        setTimeout(() => {
          this.displayModification = false;
        }, 1000);
      }
    },
    async deleteFile(item, filiale, index) {
      this.fileToDelete = item;
      this.indexFileToDelete = index;
      this.$refs['deleteFileModal'].show();
    },
    async handleSuprimerFile() {
      const response = await this.removeFilialeFile({
        file: this.fileToDelete
      });
      if (response) {
        this.hideDeleteFileModal('deleteFileModal');
        this.filialeToUpdate.files.splice(this.indexFileToDelete, 1);
        this.indexFileToDelete = null;
      }
    },
    async renameFile(item, filiale, index) {
      this.fileToRename = item;
      this.indexFileToDelete = index;
      this.inputFileToRename = this.fileToRename.name;
      this.$refs['renameFileModal'].show();
    },
    async handleRenameFile() {
      const response = await this.renameFilialeFile({
        file: this.fileToRename,
        newName: this.inputFileToRename,
        index: this.indexFileToDelete,
        item: this.filialeToUpdate
      });
      if (response) {
        this.hideDeleteFileModal('renameFileModal');
        this.indexFileToDelete = null;
        this.inputFileToRename = null;
      }
    },
    async handleSuprimerSequence() {
      this.loadingDeleteSequence = true;
      const response = await this.removeFilialeSequence({
        id: this.filialeToUpdate.id,
        index: this.indexSequencesToDelete
      });
      if (response == true) {
        this.loadingDeleteSequence = false;
        this.getSequancesofFilile.splice(this.indexSequencesToDelete, 1);
        this.indexSequencesToDelete = null;
        this.hideDeleteBienModal('deleteSequenceModal');
      } else {
        this.loadingDeleteSequence = false;
      }
    },
    async DeleteLineSequenceFactureFiliale(index, seq) {
      if (seq.hasOwnProperty('id')) {
        this.indexSequencesToDelete = index;
        this.$refs['deleteSequenceModal'].show();
      } else {
        this.getSequancesofFilile.splice(index, 1);
      }
    },
    CopyFields(payload) {
      navigator.clipboard.writeText(payload.prop);
      this.copierText = true;
      setTimeout(() => {
        this.copierText = false;
      }, 800);
    },
    async ribFiliale(filiale) {
      this.filialeToUpdate = { ...filiale };
      this.$refs['ribsFlilaleModal'].show();
      const response = await this.getAllRibOfFiliale(this.filialeToUpdate.id);
    },
    async filialeSocieteFacture(filiale) {
      this.filialeToUpdate = { ...filiale };
      this.$refs['societeFactureFlilaleModal'].show();
      const response = await this.getAllSocieteFactureOfFiliale(
        this.filialeToUpdate.id
      );
    },
    async sequencesFacturesFiliale(filiale) {
      this.filialeToUpdate = { ...filiale };
      this.$refs['sequencesFacturesFilialeModal'].show();
      const response = await this.getAllSequancesOfFiliale(filiale.id);
    },
    async biensFiliale(filiale) {
      this.getAllFilialesListe();
      this.seqeunceLoding = true;
      this.filialeToUpdate = { ...filiale };
      this.$refs['biensFilialeModal'].show();
      const response = await this.getAllBienOfFiliale(filiale.id);
      if (response) {
        this.seqeunceLoding = false;
      } else {
        this.seqeunceLoding = false;
      }
    },
    async deleteBien(item, filiale, index) {
      if (item.id == undefined) {
        filiale.biens.splice(index, 1);
      } else {
        this.bienToDelete = item;
        this.indexBienToDelete = index;
        this.$refs['deleteBienModal'].show();
      }
    },
    async handleSuprimerBien() {
      this.loadingDeleteBien = true;
      const response = await this.removeFilialeBien({
        id: this.filialeToUpdate.biens[this.indexBienToDelete].id
      });
      if (response == true) {
        this.loadingDeleteBien = false;
        this.hideDeleteBienModal('deleteBienModal');
        this.filialeToUpdate.biens.splice(this.indexBienToDelete, 1);
        this.indexBienToDelete = null;
      } else {
        this.loadingDeleteBien = false;
      }
    },
    async DeleteLineBienFiliale(index) {
      this.getBienOfiliale.splice(index, 1);
    },
    async handleUpdateBienFiliale(index, filiale) {
      const response = await this.updateBienFiliale({
        id: filiale.ribs[index].id,
        swift: filiale.ribs[index].swift,
        iban: filiale.ribs[index].iban,
        item: filiale,
        index: index
      });
      if (response == true) {
        filiale.ribs[index].FoundRib = true;
        filiale.ribs[index].changeSave = false;
      }
    },
    async BlockUnblockBienFilialeClick(index, filiale) {
      const response = await this.blockunblockBienFiliale({
        id: filiale.biens[index].id,
        blocked: filiale.biens[index].blocked ? false : true,
        item: filiale,
        index: index
      });

      if (response == true) {
      }
    },
    hideRibModal(filiale) {
      this.$refs['ribsFlilaleModal'].hide();
      this.resetBiensModal(filiale);
    },
    hideSocieteFactureModal(filiale) {
      this.$refs['societeFactureFlilaleModal'].hide();
      this.resetBiensModal(filiale);
    },
    hideBiensModal(filiale) {
      this.$refs['biensFilialeModal'].hide();
      this.resetBiensModal(filiale);
    },
    hideSequencesModal(filiale) {
      this.$refs['sequencesFacturesFilialeModal'].hide();
      this.resetSequencesModal(filiale);
    },
    resetSequencesModal(filiale) {
      // var index = 0;
      // this.sequences_factures.forEach(function(item) {
      //   if (item[0].id == undefined) {
      //     this.sequences_factures.splice(index, 1);
      //   } else {
      //     index++;
      //   }
      // });
      this.sequences_factures = [];
    },
    resetBiensModal(filiale) {
      var index = 0;
      filiale.biens.forEach(function(item) {
        if (item.id == undefined) {
          filiale.biens.splice(index, 1);
        } else {
          index++;
        }
      });
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
    },
    async addTav(type) {
      if (this.valeur > 100) {
        this.errorTvaUnite = 'tva doit etre inferieur à 100';
      } else if (this.valeur === '' || this.valeur === null) {
        this.errorTvaUnite = 'ce champ et obligatoire';
      } else {
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.$refs['addtvaModal'].hide();
          this.listTva.push(response.response.valeur);
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addtvaModal'].hide();
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'pseudos',
      'pseudo_loader',
      'errorPseudo',
      'errorRemovePseudo',
      'errorUpdatePseudo',
      'errorAddPseudo',
      'pseudo_remove_loader',
      'updateMailError',
      'errorRemoveMail',
      'loadingRemoveMail',
      'mailsIsLoading',
      'addMailsError',
      'mails',
      'getLoadingSettingFilialeTh',
      'getSettingFilialesTh',
      'getErrorSettingFilialeTh',
      'getDepotsTh',
      'getResponsableFilialeFreeTh',
      'getPaysList',
      'getLoadingSettingTypeSocieteSupport',
      'getTypeSocieteSupportTh',
      'getErrorSettingTypeSocieteSupport',
      'getLoadingSettingTypeSocieteSupport',
      'getListFilialesLocateur',
      'getListFilialesIntermidier',
      'getLoadingRibFiliale',
      'getErrorRibFiliale',
      'getLoadingRemoveFileFiliale',
      'getErrorRemoveFileFiliale',
      'getSequencesFacturesFields',
      'getMasterFilialsList',
      'getSocieteOfMasterFilial',
      'getAllTvas',
      'getRibOfFilials',
      'getRibLoader',
      'getErrors',
      'getTotalFilile',
      'getSequancesofFilile',
      'getSequanceLoder',
      'getBienOfiliale',
      'getBienLoader',
      'getSequanceErrors',
      'getBienErrors',
      'getSocieteFacturesOfFilile',
      'getSocieteFactureLoader',
      'getListFilialesLocateur'
    ]),
    isActive() {
      return this.deleted === 'no';
    },
    computedSociete() {
      if (this.deleted == 'yes') {
        return this.getSettingFilialesTh?.filter(
          item => item.deleted_at !== null
        );
      }
      return this.getSettingFilialesTh?.filter(item => item.deleted_at == null);
    },
    computedgetMasterFilialsList() {
      let newListMasterFilials = [];
      newListMasterFilials = this.getMasterFilialsList.map(item => {
        return { name: item.name, master_id: item.id };
      });
      return newListMasterFilials;
    },
    computedGetLastDateInList() {
      return this.filialeToAdd?.masterFiliales[0]?.date_fin;
    },
    computedGetLastDateInListUpdate() {
      return this.filialeToUpdate?.masterFiliales[0]?.date_fin;
    },
    computedGetDepotsTh() {
      return this.getDepotsTh;
    },
    computedGetResponsableFilialeFreeTh() {
      return this.getResponsableFilialeFreeTh.map(item => {
        return { ...item, name: item.full_name };
      });
    },
    computedGetTypeSocieteSupportTh() {
      return this.getTypeSocieteSupportTh;
    }
  },
  components: {
    DatePicker,
    'v-input-colorpicker': InputColorPicker,
    Card: () => import('../component/card.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue')
  },

  async mounted() {
    if (localStorage.getItem('Params-gestion-filiale-th')) {
      this.typeFilter = JSON.parse(
        localStorage.getItem('Params-gestion-filiale-th')
      ).type;
      this.typeSte = JSON.parse(
        localStorage.getItem('Params-gestion-filiale-th')
      ).type_societe;
      this.searchValue = JSON.parse(
        localStorage.getItem('Params-gestion-filiale-th')
      ).search;
    } else {
      this.setLocalStorageParamsFilialeTh();
    }
    this.fetchSequenceFactureFields();
    this.getAllTypeSocieteSupport();
    this.fetchDepotsTh('no');
    this.fetchResponsableFilialeFreeTh();
    this.getAllFilialesListe();
    await this.getAllMasterFilials();
    this.getSettingFilialeTh({
      per_page: this.perPage,
      page: this.page,
      type: this.typeFilter,
      type_societe: this.typeSte,
      search: this.searchValue,
      deleted: this.deleted
    });
    await this.getAllCountreies();
    let countryNames = [];
    this.getPaysList?.map(element => countryNames.push(element.nom));
    this.uniqueArrayOfCountries = [...new Set(countryNames)];
    await this.getTvas();
    this.listTva = this.getAllTvas;
  }
};
</script>

<style scoped lang="scss">
.popover-class {
  width: 100%;
}
.btn-add-email {
  background-color: #8a89d9;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 5px;
  padding: 6px 15px;
  border: none;
  float: left;
}
.color-message {
  color: red;
}
.button-settings-popover {
  border: none;
  background-color: #dee2e654 !important;
  color: black;
  border-radius: 20px;
}
.setting-filiale-th {
  width: 100%;
  .button-mail-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 7px;
    border-radius: 5px;
    &:hover,
    &:focus,
    &:active {
      font-size: 9px;
      height: 20px !important;
      width: 20px;
      padding: 3px;
      margin: 3px;
      border-radius: 5px;
      border: none;
    }
  }
}

.btn-send {
  border: none;
  color: #fff;
  background-color: #f2929a;
  float: right;
}
.btn-annuler {
  border: none;
  color: #fff;
  background-color: rgb(186, 186, 186);
  float: left;
}
.btn-delete {
  border: none;
  width: 27px;
  border-radius: 40px;
  color: #fff;
  background-color: red;
}
.btn-add {
  border: none;
  width: 27px;
  border-radius: 40px;
  margin-right: 10px;
  color: #fff;
  background-color: green;
}
#info-wrap {
  overflow: hidden;
}

.info {
  width: 80%;
  float: left;
  padding: 8px;
}
.info-right {
  width: 15%;
  float: left;
  padding-top: 35px;
  margin-left: 15px;
}
.icon-close {
  float: right;
  border: none;
  background: #fff;
  color: red;
  cursor: pointer;
}
.setting-filiale-th {
  width: 100%;

  .button-danger-style,
  .button-info-style,
  .button-succes-style {
    font-size: 9px;
    height: 20px;
    width: 20px;
    padding: 3px;
    margin: 3px;
    // border-radius: 5px;
    &:hover,
    &:focus,
    &:active {
      font-size: 9px;
      height: 20px !important;
      width: 20px;
      padding: 3px;
      margin: 3px;
      border-radius: 5px;
    }
  }

  .border-raduis {
    border-radius: 50%;
  }

  .input-focus-responsable-depot {
    width: 95%;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dddde2;
    overflow: visible;
    text-overflow: ellipsis;
    outline: unset;
    padding: 4px;
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 400;

    &:focus {
      background-color: #eeeef7;
    }
  }

  .input-focus-responsable-depot::placeholder {
    color: #c3c3d8;
    font-size: 9px;
  }

  .table-fiche {
    width: 100%;
    height: calc(100vh - 41vh);
  }

  .newcolor {
    min-width: 100px;
  }

  .actions {
    position: sticky;
    right: 0px !important;
  }

  .hearder-sticky {
    position: sticky;
    top: -1px !important;
    z-index: 50;
  }
}
.hart-not-favorit {
  color: #c1c1c1;
}
.color_picker_width {
  width: 25px;
  height: 25px;
}
.time-icon-style {
  margin-top: 33px;
  color: red;
  width: 25px;
  height: 25px;
}
.form-type {
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 56%;
  margin: auto;

  .form-groupe {
    .file-header-titles {
      align-items: center;
      padding: 4px 20px;
      display: flex;

      .file-name-title,
      .file-category-title {
        text-align: center;
        color: #495057;
        font-weight: 600;
        border-radius: 6px;
        // background-color: #8d8cb7;
        padding: 3px;
      }

      .file-name-title {
        width: 60%;
        margin-right: 10px;
      }

      .file-category-title {
        width: 40%;
      }
    }

    .files-to-upload {
      display: flex;
      align-items: center;
      padding: 2px 20px;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
        margin-right: 10px;
        text-align: left;
        font-size: 0.6rem;

        .file-upload-icon {
          color: #2dabe2;
        }
      }

      .file-category {
        width: 40%;
        font-size: 13px;
        border-radius: 17px;
      }
    }

    .actionFile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;

      .button-cancel-style {
        background-color: #49505782;
        color: white;
        border: none;
      }

      .chargement {
        margin-left: 5px;

        .spinner-border {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
  }
}
.active-user-icon {
  color: #ffffff;
  border-color: #28a745;
  background-color: #28a745;
  font-size: 13px;
  padding: 0.4rem 10px;
}
.deleted-user-icon {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 0.4rem 8px;
  font-size: 13px;
}
.box-upload {
  background-color: #ffffff;
  border-radius: 25px;
  width: 100%;
  position: relative;
  margin-right: 14px;
  padding: 0;
  color: #26292c;
}

.box-liste {
  background-color: #ffffff;
  border-radius: 25px;
  width: 50%;
  position: relative;
  margin-right: 14px;
  padding: 22px;
}

.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}

.rib_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;

  .button-add-prop-filiale-th {
    display: flex;
  }

  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;

    .action-icon {
      cursor: pointer;
    }

    .validate {
      color: green;
    }

    .cancel {
      color: #ab2525;
    }
  }
}

.sequences_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;

  .button-add-prop-filiale-th {
    display: flex;
  }
}

.bien-add-button {
  font-size: 9px;
  min-width: 70px;
  text-align: center;
  background-color: #7a78dc;
  border-radius: 3px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  padding: 5px;
  color: #ffffff;
}

.custom-date-picker-calender-filter {
  width: 125px !important;
}
.button-societe-master-filiale {
  background-color: #8d8cb7;
}

.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}

.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
</style>
<style lang="scss">
.add-Btn {
  border: none;
  float: right;
  color: #fff;
  background: #8d8cb7;
  width: 40%;
  border-radius: 40px;
}
.plus-icon-style {
  margin-top: 36px;
  color: green;
}
.times-icon-style {
  margin-top: 36px;
  color: red;
}
.table-fiche::-webkit-scrollbar {
  width: 30px;
  height: 30px;
}

.table-fiche {
  table {
    display: table !important;
  }
}

.table-fiche::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 25px;
  border: 12px solid transparent;
  background-clip: padding-box;
}

.table-fiche::-webkit-scrollbar-track {
  background: #f0eef8;
  border-radius: 30px;
  width: 15px;
  border-right: 10px solid white;
  border-left: 10px solid white;
  border-bottom: 10px solid white;
  border-top: 10px solid white;
}

.custom-select-vue-component-style {
  .multiselect__tags {
    border-radius: 4px;
    font-size: 10px;
    background-color: transparent;
    border: 1px solid #dddde2;
  }

  .multiselect__input,
  .multiselect__single {
    background: transparent;
  }
}

.labes-depot-name {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: start;
  color: #2a2a2a;
  margin-left: 3%;
  margin-top: 38px;
  margin-bottom: 1px;
  width: 30%;
}

.custom-checkbox {
  align-self: center;
  margin-right: 10px;
}

.image-preview {
  position: relative;
  display: inline;
  max-width: 112px;
}
.image-preview-inside {
  position: relative;
  display: inline;
  max-width: 250px;
}

.cutsom-modal-bootstrap-filiale-th .modal-dialog {
  top: 0%;
  max-width: 70%;
}

.width-filter {
  width: 110px;
}
</style>
<style lang="scss">
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}

.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}

// .doc-upload {
// }

.doc-list {
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;

          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}

#upload-file-component {
  overflow-y: auto;
  max-height: 321px;
  position: relative;
}

.tab-content > .active {
  overflow-y: auto;
  max-height: 412px;
  position: relative;
  width: 56%;
  margin: auto;
}

#upload-file-component::-webkit-scrollbar,
.tab-content > .active::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

#upload-file-component::-webkit-scrollbar-track,
.tab-content > .active::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}

#upload-file-component::-webkit-scrollbar-thumb,
.tab-content > .active::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}

.top-position-style > .file-content {
  top: -5px;
}

.input-modal-champ div input {
  height: 30px;
  border-color: #70707038;
  border-radius: 17px;
  width: inherit;
}

.titleFields {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
}

.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 211px;
  margin: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
}

.filed-class .icon-field {
  position: absolute;
  left: 6px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filed-class .value-field {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
}

.filed-class .icon-copy {
  position: absolute;
  right: 6px;
  width: 25px;
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholders {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#biensFilialeModal .modal-md {
  max-width: 85% !important;
}

.textarea-bien {
  line-height: 0.5;
}
.delete-prop-societe {
  font-size: 14px;
  color: red;
  position: absolute;
  right: 8px;
}
.prop-socie-block > div {
  margin: auto;
}
.action-add-prop {
  text-align-last: end;
  margin-right: -29px;
}
.style-half-circle-loder {
  padding-top: 0px !important;
  background-color: #fff !important;
}
.message-error {
  color: red;
}
</style>
<style lang="scss">
.custem-popover-societe {
  min-width: 380px !important;
  .popover-body {
  }
}
</style>
